import React, { useState } from "react";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import Homeogo from "../../assets/icons/logo.png";
import { app } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const auth = getAuth(app);
const firestore = getFirestore(app);
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleAuth = async () => {
    try {
      if (!email || !password) {
        toast.error("email and password is required");
        return;
      }
      setLoading(true);
      // Get Firestore collection reference
    const usersRef = collection(firestore, "Users");

    // Create a query to match the exact email
    const emailQuery = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(emailQuery);

    // Check if a document with the exact email exists
    if (querySnapshot.empty) {
      setLoading(false);
      toast.error("The email or password is incorrect. Please check your credentials.");
      return;
    }
      // Attempt to sign in the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // Fetch user role from Firestore after authentication
      const userDoc = await getDoc(doc(firestore, "Users", user.uid)); // Assuming your users are stored in 'Users' collection
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setLoading(false);

        // Check if the user is an admin
        if (userData.role === "admin") {
          // Navigate to the dashboardmenu page if the user is an admin
          navigate("/dashboardmenu", {
            state: {
              user: {
                uid: user.uid,
                email: user.email,
                role: "admin",
              },
            },
          });
        } else {
          // Navigate to the control page if the user is authenticated but not an admin
          navigate("/control", {
            state: {
              user: {
                uid: user.uid,
                email: user.email,
                role: "user",
              },
            },
          });
        }
      } else {
        setLoading(false);

        console.error("User document not found");
        alert("User not found. Please contact support.");
      }
    } catch (error) {
      // If authentication fails, show an alert
      setLoading(false);
      console.error("Error during sign-in:", error);
      toast.error(
        "Your email or password is incorrect. Please check your credentials and try again."
      );
    }
  };
  return (
    <div className="bg-gray-200 min-h-screen pt-20 px-4  ">
      <ToastContainer />
      <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>  

      <div className="md:w-[572px]  w-auto bg-white h-[400px] mx-auto  rounded-xl">
        <div className=" content-center mt-16 ">
          <div className=" gap-2  ">
            <div className="flex flex-row justify-center gap-4  mt-7">
              <div className="mt-3 ">
                <img src={Homeogo} width={36} height={36} className="" alt="" />
              </div>
              <div className="flex flex-row gap-4 ">
                <p className="text-5xl ">|</p>
                <h2 translate='no' className=" text-3xl mt-2">Kiip</h2>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col font-aeroport mt-5 text-center text-xl">
              <h1 className="font-semibold">User Login</h1>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="Email Address"
                className="sm:w-[393px] p-2 w-auto mt-8 h-[52px] bg-gray-200"
              />
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Password"
                className="sm:w-[393px] p-2 w-auto mt-4 h-[52px] bg-gray-200"
              />
            </div>

            {!loading && (
              <div className="flex flex-col gap-3 mt-6 items-center">
                <button
                  onClick={handleAuth}
                  className="bg-[#3ccad1]   sm:w-[395px] w-[195px] h-[52px]
             justify-center text-white font-semibold font-aeroport
             "
                >
                  Iniciar sesión
                </button>
              </div>
            )}
          </div>
          <div className="text-center mt-2">
          <h1 >Don't have an account?
            <Link to="/signup">
            <span className="text-indigo-400">Sign up</span>
            </Link>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
