// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWNzTxTMvVAs839tmCzmQlOrYtjXvn5pA",
  authDomain: "kiip-51772.firebaseapp.com",
  projectId: "kiip-51772",
  storageBucket: "kiip-51772.appspot.com",
  messagingSenderId: "206239072403",
  appId: "1:206239072403:web:3149738b8b0c15c99ecd66",
  measurementId: "G-PKJZ005DJK"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


