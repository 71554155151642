import React, { useEffect, useState } from 'react'
import Dashnavbar from './Dashnavbar'
import { useLocation, useNavigate } from 'react-router-dom'
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { app } from '../../../firebase';

function Userlisting() {
  const location = useLocation();
  const navigate = useNavigate()


  const [userData, setUserData] = useState([]); // State to store Firestore data

  const firestore = getFirestore(app)
  // Fetch Firestore data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'request'));
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data() // Spread the document data
        }));
        console.log(users);
        

        setUserData(users); // Store fetched data in state
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Format as 'MM/DD/YYYY HH:MM:SS'
    }
    return 'N/A'; // Return 'N/A' if no timestamp
  };
  return (
    <div className='bg-gray-100 min-h-screen max-h-cover'>
        <Dashnavbar/>

        <div>
            <div className='xl:px-[151px] px-8  mt-4 '>
            <div onClick={() => navigate(-1)} className=' flex flex-row  -mx-9'>
    <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className='mt-2'>Regresar</p>
    </div>
        
            <h1 className='font-aeroport font-bold text-3xl mt-9'>Listado de Usuarios</h1>
            </div>
        <div className='xl:px-[151px] px-8 pb-20 mt-4 mb-10'>
        <div className='rounded-2xl bg-white h-auto p-7 mb-8'>


<div className="overflow-x-auto md:overflow-none w-full">
          <table className="w-full gap-5   border border-gray-300 mt-6">
            {/* Table Header */}
            <thead className="bg-gray-200 text-lg font-bold font-Nunito ">
              <tr className="text-center ">
                <th className="border-b-2 py-2 px-5 ">ID</th>
                <th className="border-b-2 py-2 px-5">NOMBRES</th>
                <th className="border-b-2 py-2 px-5 ">DIRECCIÓN</th>
                <th className="border-b-2 py-2 px-5 ">Date</th>
                
                <th className="border-b-2 py-2 px-5 ">ESTADO</th>
                
              </tr>
            </thead>
            {/* Table Body */}
            <tbody className="text-center font-Nunito">
                  {userData.length > 0 ? (
                    userData.map((user) => (
                      <tr key={user.id} className="border-b border-gray-300">
                        <td className="py-3">{user.id}</td>
                        <td className="py-3">{user.name || 'N/A'}</td>
                        <td className="py-3">{user.pickup_location || 'N/A'}</td>
                        <td className="py-3">{formatDate(user.pickup_date_time)}</td>
                       
                       
                        <td className="py-3">
                          <button className='text-[#00B69B] rounded-lg bg-[#00B69B] bg-opacity-5 w-[93px] h-[27px]'>
                            {user.status || 'Pending'}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="py-3">Loading data...</td>
                    </tr>
                  )}
                </tbody>
          </table>
        </div>
        </div>

    </div>
        </div>
    </div>
  )
}

export default Userlisting