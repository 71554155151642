
import React, { useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { Await, Link, useNavigate } from 'react-router-dom'
import { collection, doc, getDocs, getFirestore } from 'firebase/firestore';
import { app } from '../../firebase';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from 'firebase/auth';


const firestore = getFirestore(app)
const auth = getAuth(app)
function Valueestimated() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openTabIndex, setOpenTabIndex] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const navigate = useNavigate();
  //  const [isOpen, setIsOpen] = useState(false);
  // const [isExpanded, setIsExpanded] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const user = auth.currentUser;
    setIsLoggedIn(!!user)

  }, [auth])
  const handleMenuClick = (index) => {
    if (openTabIndex === index) {
      setOpenTabIndex(null);
    } else {
      setOpenTabIndex(index);
    }
  };
  const handlePlanClick = (plan) => {
    if (!plan || plan.length > 1) {
      toast.error("please select plan first!")
    } else {
      // Navigate to form page and pass selected plan as state
      navigate('/form', { state: { plan } });
    }
  };
  console.log(plans);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansCollection = collection(firestore, "plans");
        const plansSnapshot = await getDocs(plansCollection);
        const plansList = plansSnapshot.docs.map(doc => doc.data());
        // Sort plansList by 'minimum' value
        const sortedPlans = plansList.sort((a, b) => a.minimum - b.minimum);
        setPlans(sortedPlans);

      } catch (error) {
        console.log("error fetching plan ", error);

      }
    }; fetchPlans();
  }, []);
  return (
    <div className=''>
      <ToastContainer />
      <div className='p-4 flex flex-row justify-between border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
          <div className='mt-3'>
            <img src={Homeogo} width={36} height={36}
              className='' alt='' />
          </div>
          <div className='flex flex-row gap-2'>
            <p className='text-5xl'>|</p>
            <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
          </div>
        </div>
        {!isLoggedIn && (

          <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
            <Link to="/login"><button className='bg-[#3ccad1] rounded-xl  hidden sm:block w-[155px] font-aeroport font-normal h-[52px]'>Iniciar sesión</button></Link>
          </div>
        )}
        <nav className=" sm:hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <button
                  type="button"
                  id="mobile-menu-button"
                  className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  onClick={toggleMenu}
                >
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Open main menu</span>
                  {/* Hamburger menu icon */}
                  <svg
                    className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                  {/* Close menu icon */}
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">

            <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
              <Link to="/login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Iniciar sesión</button>
              </Link>
            </div>

          </div>
        </nav>
      </div>
      <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>

      <div className='p-2'>
        <div className=' font-aeroport text-center lg:mt-[71px] mt-[25px]'>
          <h1 className=' font-normal text-3xl'>Elige el espacio estimado</h1>
          <p className=' font-normal text-light mt-3'>No te preocupes si no es exacto, ya que en la visita mediremos el espacio real de tus artículos</p>
        </div>
        <div className='grid lg:grid-cols-2 justify-items-center mt-6 gap-4 mb-4 font-aeroport h-[400px] '>
          {/* Render fetched plans dynamically */}
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`flex flex-col sm:w-[500px] ${openTabIndex === index ? 'h-auto' : 'h-[107px] overflow-hidden'} justify-between border-2 mt-3 gap-2 px-[17px]`}
            // Navigate to form page when plan is clicked
            >
              <div className='flex flex-row justify-between mt-5 gap-4'
              >
                <div>
                  <h1 className='font-semibold'>{plan.name}</h1>
                  <p className='font-normal'>{plan.minimum}.0m x {plan.minimum}.0m (up to {plan.upto}m³)</p>
                  <p className='font-semibold'>${plan.price} MXN</p>
                </div>
                <div className='relative inline-block text-left'>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded={openTabIndex === index ? "true" : "false"}
                    aria-haspopup="true"
                    onClick={() => handleMenuClick(index)}
                  >
                    <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>

              {openTabIndex === index && (
                <div role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="flex md:flex-row flex-col items-center mt-4 gap-4 mb-3" role="none">
                    {/* Render plan details */}
                    <div onClick={() => handlePlanClick(plan)} className='h-auto w-auto flex flex-row border-2 rounded-xl overflow-hidden'>
                      <div className='h-auto p-2 sm:w-[136px] w-[90px] bg-[#21A3AA] text-center content-center text-white font-aeroport'>
                        <h1 className='font-bold'>${plan.price}</h1>
                        <h1 className='font-normal'>MXN/ MENSUAL</h1>
                      </div>
                      <div className='text-center font-aeroport mt-3 p-2'>
                        <h1 className='font-bold mb-2'>Mes por mes</h1>
                        <p className='font-normal text-sm'>Máxima flexibilidad, sin compromiso de estancia.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className='mt-4 flex sm:flex-row flex-col gap-3 text-center'>
            <p className='mt-2 font-aeroport px-2 sm:text-lg text-md leading-6 mb-2'>Si quieres usar la calculadora?</p>
            <Link to="/addedqoute">
              <button className='bg-[#3ccad1] rounded-2xl   w-48 h-14
             justify-center text-white font-aeroport font-normal
             '>Haz clic aquí</button></Link>
          </div>
        </div>

        <div className='text-center lg:mt-9 mt-64 mb-10 font-aeroport flex sm:flex-row flex-col justify-center gap-5'>
          <p className='font-normal text-lg mt-4'>¿No sabes qué tamaño reservar?</p>
          <a
            href="https://wa.me/525586168420"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="inline-flex text-center mt-3 w-40 justify-center bg-[#3ccad1] rounded-xl px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700">
              Chat on WhatsApp
            </button>
          </a></div>
      </div>
    </div>
  )
}
export default Valueestimated


