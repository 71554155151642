import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import profile from "../../assets/icons/profile.png"
import { getAuth, signOut } from 'firebase/auth';
import { collection, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { app } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
//import './info.css'; // Adjust the path as needed
const firestore = getFirestore(app)
function Infoprofile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userEmail, userData } = location.state || {};
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(userData?.name || '');
  const [phone, setPhone] = useState(userData?.phone || '');
  console.log(userEmail);
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User logged out');
        alert("user log out successfully: ")
        navigate('/'); // Navigate to login page after logout
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  };
  console.log(userData.uid);


  const handleEditClick = () => {
    setIsEditing(prevState => !prevState);
  };

  const handleSave = async () => {
    if (!userEmail) return;
    if (!name) {
      toast.error("Please enter name");
      return;
    }
    if (!phone) {
      toast.error("Please enter Phone Number");
      return;
    }
    const userDocRef = doc(firestore, 'Users', userData.uid)
    console.log(userDocRef);
    ;
    // Adjust the collection path if needed

    try {
      await updateDoc(userDocRef, { name, phone });
      alert('Profile updated successfully');
      userData.name = name; // Update the local userData object
      userData.phone = phone; // Update the local userData object
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      alert(`Failed to update profile: ${error.message}`);
    }
  };

  const handlePaymentClick = () => {
    navigate('/payment', { state: { userData } });
  };


  return (



    <div className='min-h-screen  flex flex-col  relative overflow-hidden'>
      <ToastContainer />
      {/* Animated Floating Background Effects */}

      {/* Back Button */}
      <div onClick={() => navigate(-1)} className="absolute top-8 left-4 lg:left-12 cursor-pointer flex items-center hover:opacity-90 transition-opacity duration-300 ease-in-out">
        <button className="text-xl w-12 h-12 rounded-full bg-white  flex justify-center items-center ">
          {"<"}
        </button>
        <p className="ml-3 text-black text-lg font-semibold hover:underline ">Back</p>
      </div>

      {/* Profile Card */}
      <div className='relative items-center justify-center mt-8  mx-auto rounded-2xl p-10 text-center max-w-sm w-full '>
        {/* Profile Image with Glowing Border */}
        <div className='relative mx-auto w-24 h-24 rounded-full bg-[#3ccad1] flex items-center justify-center shadow-lg'>
          <img src={profile} alt="Profile" className='w-16 h-16 rounded-full    ' />
          <div className='absolute inset-0 rounded-full  opacity-20 blur-xl hover:opacity-30 transition-opacity duration-300'></div>
        </div>

        {/* User Information with Animation */}
        <div className='mt-4'>
          <h1 className='text-2xl font-extrabold text-gray-900 tracking-wide animate-fade-in'>{userData?.name || "User Name"}</h1>
          <h2 className='text-xl text-gray-600 mt-2 animate-fade-in-slow'>{userEmail || "Email"}</h2>
        </div>

        {/* Logout Button with Hover Effect */}
      </div>
      <div className='w-80 mx-auto'>
      <button
          onClick={handlePaymentClick}
          className='text-black border-b-2 border-black w-full font-normal text-left text-lg mt-8 py-1 px-1 hover:shadow-2xl'>
          Payment
        </button>
        <button
          onClick={handleEditClick}
          className='text-black border-b-2 border-black w-full font-normal text-left text-lg mt-3 py-1 px-1 hover:shadow-2xl'>
          Edit Profile
        </button>

        <button
          onClick={handleLogout}
          className='  text-black border-b-2 border-black w-full font-normal text-left text-lg mt-3 py-1 px-1  hover:shadow-2xl'>
          Log out
        </button>

        {/* Edit Form */}
        {isEditing && (
          <div className="mt-6 mx-auto justify-center max-w-sm  shadow-md p-2 mb-5">
            <label htmlFor=""> Nombre:</label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2 mb-4"
            />
            <label htmlFor="">Phone:</label>
            <input
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2 mb-4"
            />
            <button
              onClick={handleSave}
              className="bg-[#3ccad1] text-white w-full py-2 rounded-lg ">
              Update
            </button>
          </div>
        )}
      </div>
    </div>



  )
}

export default Infoprofile
