import React from 'react'
import workplace from '../../assets/workplace.png'
import { Link } from 'react-router-dom'

function Works() {
  // Detect the user's current language setting
  const userLang = navigator.language || navigator.userLanguage;

  // Set default Spanish text and English text for translation
  const spanishText = 'Crea más espacio en tu vida desde solo $349 al mes, todo sin salir de casa. Aquí te explicamos cómo:';
  const englishText = 'Create more space in your life starting at just $349 a month, all without leaving your home. Here’s how:';

  return (
    <div className=' p-4 mt-40 width lg:height min-w-auto mx-auto '>
        
        <div className=' flex xl:flex-row flex-col md:mt-20 '>
           <div className='xl:w-1/2'>
            <img src={workplace}
            className='sm:px-12 sm:workplaceimg min-w-auto mx-auto' alt="" />
        </div> 
        <div className='xl:w-1/2 content-center px-4 mt-16 '>
            <div className='text-center xl:text-left'> 
                <h2 className='font-bold sm:text-4xl text-2xl font-aeroport md:px-48 lg:px-0 sm:px-32 px-2'>Las limitaciones de espacio son cosa del pasado.</h2>
                {/* Conditional rendering based on user language */}
            <p className='mt-2 font-semibold font-aeroport md:px-48 lg:px-0 sm:px-32 px-2 sm:text-lg text-md leading-6 mb-4'>
              {/* {userLang.startsWith('es') ? spanishText : englishText} */}
              Crea más espacio en tu vida desde solo $349 al mes, todo sin salir de casa. Aquí te explicamos cómo:
            </p>
                <Link to="/estimatedvalue">
                <button className='bg-[#3ccad1] rounded-2xl  w-48 h-14
             justify-center text-white font-aeroport font-normal
             '>Elige tu plan</button></Link>
             
            </div>   
        </div>
        
        </div>
        
    </div>
  )
}

export default Works