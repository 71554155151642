import React from 'react'
import Homeogo from "../../assets/icons/logo.png"
import phone from "../../assets/icons/phone.png"
import navigation from "../../assets/icons/navigation.png"
import facebook from "../../assets/icons/facebook.png"
import insta from "../../assets/icons/insta.png"
import tiktok from "../../assets/icons/tiktok.png"
import youtube from "../../assets/icons/youtube.png"
const socialMediaIcons = [
    {
      name: 'Facebook',
      icon: facebook,
      link: 'https://www.facebook.com',
    },
    {
      name: 'Twitter',
      icon: tiktok,
      link: 'https://www.twitter.com',
    },
    {
      name: 'Instagram',
      icon: insta,
      link: 'https://www.instagram.com',
    },
    {
      name: 'LinkedIn',
      icon: youtube,
      link: 'https://www.linkedin.com',
    },
  ];

function Footer() {
  return (
    <div className='bg-black mt-32 w-full bg-fixed  sm:p-24 p-6 content-center flex lg:flex-row flex-col gap-16 font-aeroport rounded-t-3xl'>
        <div className='lg:w-1/4 font-aeroport'>
        <div className='flex flex-row gap-2 justify-items-center'>
            <div className='mt-3'>
                <img src={Homeogo} width={36} height={36} 
                className='' alt=''/>
            </div>
           
            <div className='flex flex-row gap-2'>
                <p className='text-5xl text-white'>|</p>
             <h2 className='text-white text-3xl mt-2'>Kiip</h2>
            </div>
        </div>
         <div>
            <h2 className='text-white mt-3'>Tu bodega personal en casa</h2>
         </div>
        </div>

        <div className='lg:w-3/4 text-white grid sm:grid-cols-3 mx-auto grid-cols-1 md:gap-16 gap-6 leading-10 text-left'>
           <div>
            <h1>¿Cómo funciona?</h1>
            <h1>Preguntas frecuentes</h1>
            <h1>Contacto</h1>
           </div>
           <div>
            <h1>Cotiza tu espacio</h1>
            <h1>Reglas de almacenamiento</h1>
            <h1>Elige tu plan</h1>
           </div>
           <div className=''>
            <div className='flex flex-row gap-1 '>
                <div>
             <img src={phone} alt=""
             className='w-6 h-6 mt-2' />
                </div>
                <div>
            <h1>+34523442345</h1>
                </div>
            </div>
            <div className='flex flex-row gap-1 mt-5'>
                <div>
             <img src={navigation} alt=""
             className='w-6 h-6 mt-3' />
                </div>
                <div className=''>
            <h1>lorem ipsum </h1>
            <h1>lorem ipsum</h1>
                </div>
            </div>
            <div className='flex mt-3'>
      {socialMediaIcons.map((item, index) => (
        <a key={index}  target="_blank" rel="noopener noreferrer" className='m-2.5 flex items-center' >
          <img src={item.icon} alt={item.name} className='w-7 h-7 mr-2  tranlate-x-4 hover:scale-125' /> {/* Display the icon image */}
           {/* Display the name */}
        </a>
      ))}
    </div>
           </div>
        </div>
    </div>
  )
}

export default Footer
