import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import contact from "../../../assets/icons/contact.png";
import gift from "../../../assets/icons/gift.png";
import watch from "../../../assets/icons/watch.png";
import { app } from "../../../firebase";

const auth = getAuth(app);
const firestore = getFirestore(app);
function Dashboardnavigation(props) {
  const [requests, setRequests] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userRequest, setUserRequest] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pending, setPending] = useState(0);
  const [pendingList, setPendingList] = useState([]);
  const [sales, setSales] = useState(0);
  const [userList, setUserList] = useState([]); // State to hold user list
  const [showUserList, setShowUserList] = useState(false);
  const [showRequestList, setShowRequestList] = useState(false);
  const [showPendingList, setShowPendingList] = useState(false); // State to hold user list
  const [requestList, setRequestList] = useState([]);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false); // Modal state
  const [selectedRequestForRejection, setSelectedRequestForRejection] =
    useState(null);
  const [rejectReason, setRejectReason] = useState(""); // Rejection reason state
  const navigate = useNavigate();
  useEffect(() => {
    const userCollection = collection(firestore, "Users");
    // Use onSnapshot to get real-time updates
    const unsubscribe = onSnapshot(userCollection, (snapshot) => {
      setUserCount(snapshot.size);
      const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUserList(users);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const userCollection = collection(firestore, "request");
    const unsubscribe = onSnapshot(userCollection, (snapshot) => {
      setUserRequest(snapshot.size);
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRequestList(requests);
      const pendingRequests = requests.filter(
        (request) => request.status == null || request.status === undefined
      );
      setPendingList(pendingRequests);
      setPending(pendingRequests.length);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    console.log("onAuthStateChanged1");

    var userSnahot = onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged2");
      userSnahot();
      if (user == null || undefined) {
        navigate("/login", {});
        return;
      }
      const userDoc = await getDoc(doc(firestore, "Users", user.uid)); // Assuming your users are stored in 'Users' collection
      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Check if the user is an admin
        if (userData.role === "admin") {
          // Navigate to the dashboardmenu page if the user is an admin
          navigate("/dashboardmenu", {
            state: {
              user: {
                uid: user.uid,
                email: user.email,
                role: "admin",
              },
            },
          });
        } else {
          // Navigate to the control page if the user is authenticated but not an admin
          navigate("/control", {
            state: {
              user: {
                uid: user.uid,
                email: user.email,
                role: "user",
              },
            },
          });
        }
      } else {
        console.error("User document not found");
        alert("User not found. Please contact support.");
      }
    });
  }, []);
  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate();
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
    return "N/A";
  };

  const toggleUserList = () => {
    setShowUserList(!showUserList); // Toggle the visibility of the user list
  };

  const toggleRequestList = () => {
    setShowRequestList(!showRequestList); // Toggle the visibility of the request list
  };
  const togglePendingList = () => {
    // This will show the pending list when clicked
    setShowPendingList(!showPendingList);
  };
  const rejectRequest = async (e) => {
    if (!rejectReason) {
      alert("Please provide a reason for rejection.");
      return;
    }
    const requestCollection = collection(firestore, "request");
    await updateDoc(doc(firestore, "request", selectedRequestForRejection.id), {
      status: "rejected",
      reject_reason: rejectReason,
    });
    e.status = "rejected";
    setRequests((prevItems) =>
      prevItems.map((item) =>
        item.id === selectedRequestForRejection.id
          ? { ...item, status: "Rejected", reject_reason: rejectReason }
          : item
      )
    );
    alert("Request rejected");
    setIsRejectModalOpen(false); // Close the modal
    setRejectReason(""); // Clear the reason input
  };
  const acceptRequest = async (e) => {
    try {
      setLoading(true);
      var uid = e.created_by;
      var price = e.payment;
      var reqId = e.id;
      console.log("payment__", {
        uid: uid,
        price: price,
        name: "adrfr",
        requestId: reqId,
        customerId: e.customerId,
      });
      const response = await fetch(
        "https://us-central1-kiip-51772.cloudfunctions.net/subscribe/subscribe",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            uid: uid,
            price: price,
            name: "adrfr",
            requestId: reqId,
            customerId: e.customerId,
          }).toString(),
        }
      );
      const data = await response.json(); // or await response.text(), based on your expected response format
      console.log("payment__", data);
      await updateDoc(doc(firestore, "request", e.id), {
        status: "accepted",
      });
      e.status = "accepted";
      setRequests((prevItems) =>
        prevItems.map((item) =>
          item.id === e.id ? { ...item, status: "accepted" } : item
        )
      );
      setUploading(false);
      setLoading(false);
      alert("Request accepted");
    } catch (error) {
      setUploading(false);
      alert(error);
    }
    // alert(JSON.stringify(e));
  };

  if (loading) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400">
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>
        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>
        {/* Loading Text with Soft Animation */}
        <p className="relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider">
          Loading...
        </p>
      </div>
    );
  }

  return (
    <div className="xl:px-[151px] px-8 mt-0">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3 justify-items-center">
        {/*1st */}

        <div onClick={() => navigate("/totaluser")}>
          <div className="w-[240px] h-[110px] rounded-2xl bg-white mt-4 p-3">
            <div className="flex flex-row justify-between">
              <div>
                <h1 className="text-xl text-gray-500 font-extralight mb-4">
                  Total de Usuarios
                </h1>
                <h1 className=" text-2xl font-bold">
                  {userCount.toLocaleString()}
                </h1>
              </div>
              <div>
                <img src={contact} alt="" className="w-[60px] h-[60px]" />
              </div>
            </div>
          </div>
        </div>
        {/*2nd */}
        <div
          onClick={
            () => {
              props.onClick(false);
            }
            //toggleRequestList
          }
        >
          <div className="w-[240px]  h-[110px] bg-white rounded-2xl mt-4 p-3">
            <div className="flex flex-row justify-between">
              <div>
                <h1 className="text-xl text-gray-500 font-extralight mb-4">
                  Total de Pedidos
                </h1>
                <h1 className=" text-2xl font-bold">
                  {userRequest.toLocaleString()}
                </h1>
              </div>
              <div>
                <img src={gift} alt="" className="w-[60px] h-[60px]" />
              </div>
            </div>
          </div>
        </div>

        {/*4th */}
        <div
          onClick={
            () => {
              props.onClick(true);
            }
            //togglePendingList
          }
        >
          <div className="w-[240px] h-[110px] bg-white rounded-2xl mt-4 p-3">
            <div className="flex flex-row justify-between">
              <div>
                <h1 className="text-xl text-gray-500 font-extralight mb-4">
                  Pendientes Totales
                </h1>
                <h1 className=" text-2xl font-bold">
                  {pending.toLocaleString()}
                </h1>
              </div>
              <div>
                <img src={watch} alt="" className="w-[60px] h-[60px]" />
              </div>
            </div>
          </div>
        </div>
        {/* Conditional Rendering of User List */}
      </div>
      {showUserList && (
        <div className="mt-4 p-3 bg-white rounded-2xl">
          <h2 className="text-xl font-bold">Lista de Usuarios</h2>
          <ul className="list-disc list-inside">
            {userList.map((user) => (
              <li key={user.id} className="text-gray-700">
                {user.email}{" "}
                {/* Change to any user property you want to display */}
              </li>
            ))}
          </ul>
        </div>
      )}

      {showPendingList && (
        <div className="mt-32 flex justify-center items-center p-4 bg-white rounded-2xl shadow-md">
          <div className="w-full ">
            {" "}
            {/* Set a max width for the table */}
            <h2 className="text-xl font-bold text-gray-800 mb-3">Pendings</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-gray-200 text-gray-600">
                  <th className="py-3 px-4 text-left">Ubicación de Recogida</th>
                  <th className="py-3 px-4 text-left">
                    Fecha y Hora de Recogida
                  </th>
                  <th className="py-3 px-4 text-left">Tamaño</th>
                  <th className="py-3 px-4 text-left">Pago</th>
                  <th className="py-3 px-4 text-left">Estado</th>
                  <th className="py-3 px-4 text-left"></th>
                  <th className="py-3 px-4 text-left"></th>
                </tr>
              </thead>
              <tbody>
                {pendingList.map((request) => (
                  <tr
                    key={request.id}
                    className="border-b hover:bg-gray-100 gap-3"
                  >
                    <td className="py-3 px-4 text-gray-700">
                      {request.pickup_location}
                    </td>
                    <td className="py-3 px-4 text-gray-700">
                      {formatDate(request.pickup_date_time)}
                    </td>
                    <td className="py-3 px-4 text-gray-700">
                      {request.size} m³
                    </td>
                    <td className="py-3 px-4 text-gray-700">
                      {request.payment} MXN
                    </td>
                    <td className="py-3 px-4 text-gray-700">
                      {request.status || "Pending"}
                    </td>
                    {!request["status"] && (
                      <td
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent parent click event
                          acceptRequest(request);
                        }}
                        className="py-3 px-3 text-green-500 font-semibold"
                      >
                        Accept
                      </td>
                    )}
                    {!request["status"] && (
                      <td
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent parent click event
                          setSelectedRequestForRejection(request);
                          //rejectRequest(request);
                          setIsRejectModalOpen(true);
                        }}
                        className="py-3 text-red-600 font-semibold"
                      >
                        Reject
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {isRejectModalOpen && (
              <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h2 className="text-xl font-bold mb-4">Reject Request</h2>
                  <textarea
                    className="w-full p-2 border border-gray-300 rounded"
                    rows="4"
                    placeholder="Enter the reason for rejection"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                  <div className="mt-4 flex justify-end">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                      onClick={rejectRequest}
                    >
                      Reject
                    </button>
                    <button
                      className="bg-gray-300 px-4 py-2 rounded"
                      onClick={() => setIsRejectModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* Conditional Rendering of Request List */}
      {showRequestList && (
        <div className="mt-32 justify-center  p-4 bg-white rounded-2xl shadow-md">
          <h2 className="text-xl font-bold text-gray-800 mb-3">
            Lista de Pedidos
          </h2>
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="py-3 px-4 text-left">Ubicación de Recogida</th>
                <th className="py-3 px-4 text-left">
                  Fecha y Hora de Recogida
                </th>
                <th className="py-3 px-4 text-left">Tamaño</th>
                <th className="py-3 px-4 text-left">Pago</th>
                <th className="py-3 px-4 text-left">Estado</th>
              </tr>
            </thead>
            <tbody>
              {requestList.map((request) => (
                <tr key={request.id} className="border-b hover:bg-gray-100">
                  <td className="py-3 px-4 text-gray-700">
                    {request.pickup_location}
                  </td>
                  <td className="py-3 px-4 text-gray-700">
                    {formatDate(request.pickup_date_time)}
                  </td>
                  <td className="py-3 px-4 text-gray-700">{request.size} m³</td>
                  <td className="py-3 px-4 text-gray-700">
                    {request.payment} MXN
                  </td>
                  <td className="py-3 px-4 text-gray-700">
                    {request.status || "Pending"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Dashboardnavigation;
