import React from 'react'
import icon1 from "../../assets/icons/pickup.png"
import icon2 from "../../assets/icons/storage.png"
import icon3 from "../../assets/icons/delivery.png"
import isolation from "../../assets/icons/isolation.png"
import no1 from "../../assets/icons/no1.png"
import no2 from "../../assets/icons/no2.png"
import no3 from "../../assets/icons/no3.png"
import no4 from "../../assets/icons/no4.png"

function Dashflexiblework() {
  return (
    <div>
        <div className='md:p-[46px] p-[16px] '>
        <div className='bg-slate-300 bg-fixed rounded-2xl pt-6'>

        <div className='text-center'>
            <h2 className='font-bold font-aeroport sm:text-5xl text-3xl mt-[71px]'>Cómo Funciona</h2>
            <p className='font-semibold font-aeroport sm:text-2xl text-lg mt-5'>Nos encargamos de todo para que sientas que es magia</p>
        </div>
        <div className=' flex lg:flex-row flex-col lg:mt-[64px] mt-[20px] py-10'>
        <div className='grid lg:grid-cols-2 grid-cols-1 2xl:px-[69px] mx-2 sm:mx-10 sm:px-[40px] px-[3px] mt-10  '>
        <div className='flex  flex-col sm:gap-[40px] gap-[10px] '>
            <div>
                <img src={isolation}
                width={106}
                height={106} alt="money-bag" />
            </div>
            <div className=' '>
                <div  className='flex flex-row gap-2'>
                  <img src={no1} alt=""
                  width={35}
                  height={35} />
                <h2 className='font-bold sm:text-4xl text-2xl font-aeroport'>Selecciona artículos</h2>
            </div>
                <p className='mt-1 font-semibold font-aeroport text-[#21A3AA] sm:text-lg  leading-6 mb-[45px] '>Selecciona los artículos que deseas guardar y<br/>programa una visita en el día y hora que<br/>mejor te convenga</p>
                </div>
           </div>
           <div className='flex  flex-col sm:gap-[40px] gap-[16px] lg:mt-48 mt-6 lg:ml-28'>
            <div>
                <img src={icon1}
                width={106}
                height={106} alt="money-bag" />
            </div>
            <div className=' '>
            <div  className='flex flex-row gap-2'>
                  <img src={no2} alt=""
                  width={35}
                  height={35} />
                <h2 className='font-bold sm:text-4xl text-2xl font-aeroport'> Recogemos</h2>
            </div> 
                
                <p className='mt-1 font-semibold font-aeroport sm:text-lg text-[#21A3AA] leading-6 mb-[45px] '>Recogemos tus artículos desde cualquier ubicación que<br/>especificas, los protegemos durante el transporte y<br/>los manejamos con cuidado.</p>
            </div>
           </div>

           <div className='flex flex-col sm:gap-[40px] gap-[16px] mt-6'>
            <div>
                <img src={icon2}
                width={106}
                height={106} alt="money-bag" />
            </div>
            <div className=' '>
            <div  className='flex flex-row gap-2'>
                  <img src={no3} alt=""
                  width={35}
                  height={35} />
                <h2 className='font-bold sm:text-4xl text-2xl font-aeroport'>Almacenamos</h2>
            </div> 
                
                <p className='mt-1 leading-6 font-semibold font-aeroport text-[#21A3AA] sm:text-lg mb-[45px] '>Tus pertenencias son importantes para nosotros. Están<br/>monitoreadas 24/7, permitiéndote relajarte<br/>y disfrutar de tu hogar, familia o viaje.</p>
            </div>
           </div>


           <div className='flex  flex-col sm:gap-[40px] gap-[16px] lg:mt-48 mt-6 lg:ml-28'>
            <div>
                <img src={icon3}
                width={106}
                height={106} alt="money-bag" />
            </div>
            <div className=' '>
            <div  className='flex flex-row gap-2'>
                  <img src={no4} alt=""
                  width={35}
                  height={35} />
                <h2 className='font-bold sm:text-4xl text-2xl font-aeroport'>Devolvemos</h2>
            </div> 
                
                <p className='mt-1 leading-6 font-semibold sm:text-lg mb-[45px] text-[#21A3AA] font-aeroport '>Con un clic, selecciona los artículos que necesitas de<br/>tu inventario, y te los entregaremos<br/>donde y cuando quieras.</p>
            </div>
           </div>
        </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Dashflexiblework