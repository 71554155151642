import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, getFirestore } from 'firebase/firestore';
import { app } from '../../../firebase'; // Adjust the path according to your project structure
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions
import { useNavigate } from 'react-router-dom';

const firestore = getFirestore(app);
const storage = getStorage(app);

function Dashboardrequest() {
  const [requests, setRequests] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingRequestId, setUploadingRequestId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  // Fetch data from Firestore
  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(firestore, 'request'));
        const data = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRequests(data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
       setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  // Handle file selection for multiple files
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  // Handle multiple file upload for a specific request
  const handleUpload = async (requestId) => {
    if (selectedFiles.length === 0) {
      alert('No files selected.');
      return;
    }

    try {
        setLoading(true)
      const uploadPromises = Array.from(selectedFiles).map(async (file) => {
        const fileName = `${Date.now()}_${file.name}`;
        const storageRef = ref(storage, `images/${requestId}/${fileName}`);
        
        // Upload each file
        await uploadBytes(storageRef, file);
        
        // Get the image URL
        const imageURL = await getDownloadURL(storageRef);
        return imageURL;
      });

      // Wait for all uploads to complete and get the image URLs
      const imageURLs = await Promise.all(uploadPromises);

      // Update Firestore document with the image URLs (save as an array)
      const requestDocRef = doc(firestore, 'request', requestId);
      
      await updateDoc(requestDocRef, {
        images: imageURLs // Save the array of image URLs
      });

      console.log('Images uploaded and URLs saved successfully for request', requestId);
      alert('Files uploaded and URLs saved successfully!');
      setSelectedFiles([]); // Clear selected files
      setUploadingRequestId(null); // Reset the state
      setLoading(false)
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Failed to upload files.');
    }
  };

  return (
    <div className='p-10 min-h-screen bg-gradient-to-br from-blue-100 via-pink-100 to-purple-200 flex flex-col items-center'>
       <div onClick={() => navigate(-1)} className=' flex flex-row lg:mr-[1000px] md:mr-[550px] mr-[170px]'>
    <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className='mt-2'>Regresar</p>
    </div>
      <h2 className='text-5xl font-extrabold mb-10 mt-9 text-white drop-shadow-lg text-center'>
        Request Dashboard
      </h2>

      {loading ? (
        <p className='text-center text-lg text-gray-700 font-semibold'>Loading...</p>
      ) : (
        <div>
          {requests.length > 0 ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
              {requests.map((request, index) => (
                <div
                  key={index}
                  className='relative p-6 rounded-xl bg-white bg-opacity-80 backdrop-blur-md border border-gray-300 shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105 flex flex-col justify-between'
                >
                  <div className='flex-grow'>
                    <h3 className='font-bold text-2xl mb-4 text-purple-900'>Request ID: {request.id}</h3>
                    <p className='text-gray-800 mb-2'><strong>Location:</strong> {request.pickup_location}</p>
                    <p className='text-gray-800 mb-2'><strong>Payment:</strong> ${request.payment}</p>
                    <p className='text-gray-800 mb-2'><strong>Created by ID:</strong> {request.created_by}</p>
                    {/* <p className='text-gray-800 mb-2'><strong>Plan_Id:</strong> {request.plan_id}</p> */}
                    <p className='text-gray-800 mb-2'>
                      <strong>Pickup Date:</strong> {request.pickup_date_time ? new Date(request.pickup_date_time.seconds * 1000).toLocaleDateString() : 'No pickup date available'}
                    </p>
                    <p className='text-gray-800 mb-2'>
                      <strong>Created at:</strong> {request.created_at ? new Date(request.created_at.seconds * 1000).toLocaleDateString() : 'No created date available'}
                    </p>

                    <p className='text-gray-800 font-semibold mt-3'>Items List:</p>
                    {request.items_list && Array.isArray(request.items_list) ? (
                      <ul className='list-disc list-inside mb-4'>
                        {request.items_list.map((item, idx) => (
                          <li key={idx} className='text-gray-700'>
                            <strong>Item ID:</strong> {item.item_id}, <strong>Quantity:</strong> {item.quantity}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No items available</p>
                    )}

                    <p className='text-gray-800 mt-4'>
                      <strong>Images:</strong> {request.images ? request.images.length : 0} uploaded
                    </p>
                  </div>

                  <div className='mt-6 flex flex-col items-center'>
                    <input
                      type='file'
                      multiple
                      onChange={handleFileChange}
                      className='block w-full text-sm text-gray-800 bg-gray-200 rounded-md border border-gray-300 cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#3ccad1] transition-all duration-200'
                    />
                    <button
                      onClick={() => {
                        setUploadingRequestId(request.id);
                        handleUpload(request.id);
                      }}
                      className='mt-4 w-full bg-[#3ccad1] text-white font-semibold py-2 px-4 rounded-lg shadow-lg hover:shadow-xl hover:bg-[#2aa3b1] transition-all duration-300 relative overflow-hidden'
                    >
                      <span className='absolute inset-0 bg-white bg-opacity-10 opacity-0 hover:opacity-20 transition-opacity duration-300'></span>
                      Upload Images
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className='text-center text-lg text-gray-700'>No requests found.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboardrequest;
