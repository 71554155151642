import React, { useEffect } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import {useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import plus from "../../assets/icons/plus.png"
import minus from "../../assets/icons/minus.png"
import { app } from "../../firebase"
import {getFirestore, getDocs, collection, addDoc } from 'firebase/firestore';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from 'firebase/auth';

//import selfquote from '../../assets/selfquote.png'
const firestore = getFirestore(app)

const auth = getAuth(app);

const ItemList = ({ items, onItemSelect, counter, incrementCounter, decrementCounter }) => {
  const scrollRef = useRef(null);
  const [activeItem, setActiveItem] = useState(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: direction === "left" ? -200 : 200, behavior: 'smooth' });
    }
  };

  const handleItemClick = (item) => {
    setActiveItem(item.id); // Set the active item based on the clicked item ID
    onItemSelect(item);      // Call the parent function with the selected item
  };

  return (
    <div className="relative px-9">
      <button onClick={() => scroll('left')} className="absolute left-0 top-5 transform pb-1 px-3 text-gray-300 -translate-y-1/2 z-10 text-2xl bg-gray-700 justify-items-center rounded-full -ml-2 font-extralight">
        {"<"}
      </button>
      
      <div className="overflow-hidden w-full">
        <div
          className="flex gap-3 whitespace-nowrap w-full no-scrollbar-inner"
          ref={scrollRef}
        >
          {items.map((item) => (
            <button
              key={item.id}
             // onClick={() => handleItemClick(item)}
              className={`h-12 w-auto px-6 gap-10 flex flex-row ${activeItem === item.id ? 'bg-[#3ccad1] text-white' : 'bg-gray-100 text-black'} rounded-full font font-aeroport`}
            >
              <p className="mt-2">{item.es_name}</p>
              <div className="flex flex-row justify-center gap-2 mt-2 w-5">
                <img src={plus} alt="plus" onClick={(e) =>{ incrementCounter(item.es_name);
                  handleItemClick(item); 
                }} className="w-4.5 h-4.5 bg-[#000000] rounded-full mt-1" />
                <p>{counter[item.es_name] || 0}</p>
                <img src={minus} alt="minus" onClick={() => decrementCounter(item.es_name)} className="w-4.5 h-4.5 bg-[#000000] rounded-full mt-1" />
              </div>
            </button>
          ))}
        </div>
      </div>

      <button onClick={() => scroll('right')} className="absolute right-0 top-5 pb-1 px-3 text-gray-300 transform -translate-y-1/2 z-10 text-2xl bg-gray-700 -mr-1 lg:-mr-2 rounded-full font-extralight">
        {">"}
      </button>
    </div>
  );
};
const ItemDetails = ({ item ,counter, onRemove,resetCounter}) => {
  if (counter === 0) return null;
  return (
    
  <div className="bg-[#F1F1F1] gap-4 mt-3 p-3 items-center flex flex-row rounded-2xl">
    <div>
      <h3 className='bg-[#3ccad1] w-8 h-8 text-center rounded-full pt-1 text-white'>{item.quantity=counter}</h3>
    </div>
    <div>
    <div className='hidden lg:flex  flex-col'>
    <div className='flex flex-row  gap-2 font-aeroport'>
    <p className=" font-semibold text-xs">{item.es_name}</p>  
    <p className=" font-semibold text-xs"> Weight {item.weight}kg</p>
    </div>
    <div className='flex flex-row gap-2 mt-2'>
    <p className=" font-semibold text-xs  ">Width:{item.width}cm</p>
    <p className=" font-semibold text-xs ">Depth:{item.depth}cm</p>
    <p className=" font-semibold text-xs  ">Height:{item.height}cm</p>
    </div>
    </div>
    <div className=' lg:hidden'>
    <div className='grid sm:grid-cols-3 grid-cols-2  gap-2  font-aeroport'>
    <p className=" font-semibold text-xs">{item.name}</p> 
    <p className=" font-semibold text-xs"> Weight {item.weight}kg</p>
    </div>
    <div className='grid sm:grid-cols-3 grid-cols-2 gap-2 mt-2'>
    <p className=" font-semibold text-xs  ">Width:{item.width}cm</p>
    <p className=" font-semibold text-xs ">Depth:{item.depth}cm</p>
    <p className=" font-semibold text-xs  ">Height:{item.height}cm</p>
    </div>
    </div>
    </div> 
    <button 
      onClick={() => {onRemove(item.id);
        resetCounter(item.es_name);}
      }
      className=" px-4 py-2 ml-auto"
    >
     <img src={minus} alt="remove items"  className='w-5 h-5  bg-[#000000] rounded-full mt-1'/>
    </button>
    </div>
  )
};
function Addedquote() {
  
  const location = useLocation();
  console.log(location.state);
  const { user,userData} = location.state || {};
  console.log(user);
  console.log(userData);

  
  
  const [buttonsData, setButtonsData] = useState([]);
  const [visibleList, setVisibleList] = useState([]); 
  const [selectedItems, setSelectedItems] = useState({});
  const [counter, setCounters] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  console.log(selectedItems);
  
    // Function to fetch data from Firestore


  useEffect(() => {
const getDocuments = async () => {
      try {
          const querySnapshot = await getDocs(collection(firestore, "item_categories"));
          const formattedData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
          }));
          setButtonsData(formattedData);
          
      } catch (error) {
          console.error("Error fetching documents:", error);
      }
  };

  const getDocument = async () => {
    try {
        const querySnapshot = await getDocs(collection(firestore, "items"));
        const formattedData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setVisibleList(formattedData);
        
        
    } catch (error) {
        console.error("Error fetching documents:", error);
    }
};

      getDocuments();
      getDocument();
  }, []);
  const handleItemSelect = (item) => {
    setSelectedItems(prevState => ({
      ...prevState,
      [item.id]: item
      
      
      
    }));
    
    
  };
  useEffect(() => {
    if (visibleList) {
      // Initialize counters for new visible list items
      const newCounters = { ...counter };
      visibleList.forEach(item => {
        if (!(item.name in newCounters)) {
          newCounters[item.name] = 0;
        }
      });
      setCounters(newCounters);
    }
  }, [visibleList]);
  const navigate = useNavigate();
  const incrementCounter = (itemName) => {
    setCounters((prevCounters) => ({
      ...prevCounters,
      [itemName]: (prevCounters[itemName] || 0) + 1
    }));
  };
  const decrementCounter = (itemName) => {
    setCounters((prevCounters) => ({
      ...prevCounters,
      [itemName]: (prevCounters[itemName] || 1) - 1
    }));
  };
  const resetCounter = (itemName) => {
    setCounters((prev) => ({
      ...prev,
      [itemName]: 0,
    }));
  };
  useEffect(() => {
    // Check if the user is logged in
    const user = auth.currentUser;
    setIsLoggedIn(!!user);
  }, [auth]);
  const handleButtonClick = (categoryId) => {
    setSelectedCategory(categoryId);

  };
  const filteredItems = visibleList.filter(item => {
    return item.category === selectedCategory;
  });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    const handleRemoveItem = (itemId) => {
      setSelectedItems(prevState => {
        const newState = { ...prevState };
        delete newState[itemId];
        return newState;
      });
    };
    console.log(selectedItems);
    const handleNavigation = async () => {
      // Create a copy of selectedItems and remove items with zero quantity
      const updatedSelectedItems = { ...selectedItems };
      
      for (const itemId in updatedSelectedItems) {
        const item = updatedSelectedItems[itemId];
        const itemQuantity = counter[item.es_name] || 0;
        
        // Remove items with zero quantity
        if (itemQuantity === 0) {
          delete updatedSelectedItems[itemId];
        }
      }
    
      // Check if there are any items left after removing zero-quantity items
      if (Object.keys(updatedSelectedItems).length === 0) {
        toast.error("Please select at least one item with a quantity before proceeding.");
        return;
      }
    
      try {
       
        navigate('/recomend', { state: { selectedItems: updatedSelectedItems, user, userData } });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    };
    

   if (loading) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400">
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>

        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>

        {/* Loading Text with Soft Animation */}
        <p className="relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider">
          Loading...
        </p>
      </div>
    );
  }
   
  return (
    <div className='bg-gray-100 min-h-screen h-full'>
      <ToastContainer /> {/* Add this line */}
       <div className='p-4 flex flex-row justify-between border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
            <div className='mt-3'>
                <img src={Homeogo} width={36} height={36} 
                className='' alt=''/>
               
                
            </div>
           
            <div className='flex flex-row gap-2'>
                <p className='text-5xl'>|</p>
             <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
            </div>
        </div>
        {!isLoggedIn && (
        
        <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
        <Link  to="/login"><button className='bg-[#3ccad1] rounded-xl  hidden sm:block w-40 font-aeroport font-normal h-14'>Iniciar sesión</button></Link>
        </div>
        )}
            
        <nav className=" sm:hidden">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              id="mobile-menu-button"
              className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={toggleMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              {/* Hamburger menu icon */}
              <svg
                className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              {/* Close menu icon */}
              <svg
                className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}  id="mobile-menu">
        <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
        <Link to="/login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Iniciar sesión</button>
        </Link> </div>
      </div>
    </nav>
    </div>
    <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-6.5 h-6.5">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>   
    <div className='flex flex-col items-center mt-20 '>
        <div>
        <h2 className='text-4xl font-bold font-aeroport'>Auto-cita</h2>
        </div>
        <div className='flex flex-row  gap-10 lg:mx-auto mt-8 mx-1 '>       
        <div className='lg:w-[1070px] w-[344px] gap-3 sm:w-[600px] flex lg:flex-row flex-col   h-auto  rounded-3xl sm:p-6 p-4.5 mb-36'>
           <div className='bg-white p-9 rounded-xl'>
            <div className='flex flex-col'>
            <div className='border-b-2 '>
                <h2 className='text-xl font-semibold font-aeroport text-center mb-3 sm:text-left'>Seleccionar Categoría</h2>
            </div>
            <div className='mt-4 text-center lg:flex lg:flex-col space-x-0  sm:space-x-1 space-y-3'>
            {buttonsData.map(button => (
                <button
                    key={button.id}
                    onClick={() => handleButtonClick(button.id)}
                    className='w-44 h-14 focus:bg-[#3ccad1] font-semibold bg-gray-100 font-aeroport rounded-full focus:text-white font-aeroport px-4'
                >
                    {button.es_name}
                </button>
            ))}
               </div>
            </div> 
           </div>
           <div className='lg:w-3/4  gap-4 '>

            <div className='flex flex-col bg-white mt-1 p-2 px-3 rounded-xl'>
            <div className='border-b-2'>
                <h2 className='text-xl text-center sm:text-left mb-3 font-semibold font-aeroport'>Seleccionar Artículos</h2>
            </div>
            <div className='mt-4 text-center w-full' >                   
            {selectedCategory && <ItemList items={filteredItems} onItemSelect={handleItemSelect}  counter={counter} incrementCounter={incrementCounter}  decrementCounter={decrementCounter} />}
            </div>
          
            </div> 
            <div className='flex flex-col bg-white items-center md:block mt-3 min-h-96 max-h-auto p-2 px-3 rounded-xl'>
            <div className=''>
              <div className='border-b-2'>
                <h2 className='text-xl text-center sm:text-left mb-3  font-semibold font-aeroport'>Artículos Agregados</h2>
                

              </div>
                <div className='flex flex-row  sm:gap-4 gap-2 items-center mt-4   sm:h-auto h-auto md:w-auto sm:px-2  '>
                {Object.keys(selectedItems).length > 0 && (
                  <div className='font-aeroport font-normal mt-5 item-center'>
                     
       

                    {Object.values(selectedItems).map((item,index) => (
            <ItemDetails  onRemove={handleRemoveItem} resetCounter={resetCounter} key={index} item={item}   counter={counter[item.es_name] || 0}/>
          ))}
          
                  </div>
                )}
                </div>
            </div>
            <div className='md:text-right text-center md:mt-60 mt-10 mb-6'>
            
            <button onClick={handleNavigation} className='bg-[#3ccad1] w-48 h-12 rounded-2xl font-aeroport text-white font-semibold '
            
            >CALCULAR</button>
            
            </div>
            </div> 
           </div>
        </div>
        </div>
    </div>
    </div>
  )
}
export default Addedquote


 


         