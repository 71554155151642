import React from 'react'
import Dashhome from './Dashhome'
import Dashfeature from './Dashfeature'
import Dashflexiblework from './Dashflexiblework'

function Dashboard() {
  return (
    <div>
      
        <Dashhome/>
        <Dashfeature/>
        <Dashflexiblework/>
    </div>
  )
}

export default Dashboard