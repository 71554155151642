import React, { useEffect, useState } from 'react';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { app } from '../../../firebase'; // Adjust this path to your firebase config
import { useNavigate } from 'react-router-dom';
import phone from '../../../assets/icons/phone.png'

const firestore = getFirestore(app);

function TotalUser() {
    const navigate = useNavigate()
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    // Access the "Users" collection in Firestore
    const userCollection = collection(firestore, 'Users');
    
    // Fetch the data in real-time using onSnapshot
    const unsubscribe = onSnapshot(userCollection, (snapshot) => {
      const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUserList(users);
    });

    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <div className="p-6 bg-gray-200 rounded-xl shadow-lg">
        <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
      <h1 className="text-2xl font-bold text-gray-700 mb-4">Total Users</h1>
      <ul className="list-disc list-inside text-gray-600">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
        {userList.map((user) => (
          <div key={user.id} className="bg-white rounded-lg p-4 shadow-md">
            <h2 className="text-xl font-semibold">{user.name}</h2>
            <p className="text-gray-700">
            
                Email: {user.email}</p>
            <p className="text-gray-700 ">
            
             

                Phone: {user.phone}
            </p>
            {/* Add any other user details you want to display */}
          </div>
        ))}
      </div>
      </ul>
    </div>
  );
}

export default TotalUser;
