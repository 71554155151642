import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCards, saveCard, } from '../nanopages/Stripecall';
import { deleteCard } from '../nanopages/Stripecall';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast, ToastContainer } from 'react-toastify';
const ss = require("stripe")(
  "sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK"
);
const stripePromise = loadStripe(
  "pk_test_51Poq3nRrhvPQKbL0xDAfF1lSSgY8Gx8ZSkTXLH2zcdg1nV05GWNiPlwtE41tdugWIiA5JvLi6RkQRKsp4PribGzq00CNhRWlN2"
);

function Payment() {
  const location = useLocation();
  const { userData } = location.state || {};
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSubmit = async (e, stripe, elements) => {
    e.preventDefault();


    try {
      setLoading(true);
      console.log(loading);

      console.log("Hello Brother");

      // const customer = await getCustomer();
      console.log(userData);
      var customerId = userData.customerId;
      if (customerId == null || customerId == undefined) {
        const customers = await ss.customers.search({
          query: "email:'" + userData.email + "'",
          limit: 1,
        });
        console.log("customerId", customers.data);
        if (customers.data.length != 0) {
          customerId = customers.data[0].id;
        }
      }
      console.log("customerId__", customerId);

      if (customerId == null || customerId == undefined) {
        const customer = await ss.customers.create({
          name: `${userData.name} ${userData.lastName}`,
          email: `${userData.email}`,
        });
        console.log("customerId", customer);

        customerId = customer.id;
      }

      setLoading(false);


      console.log("customerId", customerId);
      console.log("elements", elements);

      setShowCardDetails(true);
      if (!showCardDetails) return;

      if (!stripe || !elements) {
        console.log("Not yet");
        // Stripe.js has not yet loaded.
        return;
      }
      const cardElement = elements.getElement(CardElement);
      console.log(cardElement);

      // Use Stripe.js methods to handle the payment
      var paymentResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      console.log("paymentResponse", paymentResponse.error);
      if (paymentResponse.error) {
        alert(paymentResponse.error.message);
        setLoading(false);
        return;
      }
      const { paymentMethod } = paymentResponse;
      console.log(`Method>>>${paymentMethod}`);
      const payment = await ss.paymentMethods.attach(paymentMethod.id, {
        customer: customerId,
      });
      console.log("End?>>>>>>>>>>>>>>>>>>>>>>>>> payment", payment);
      // const cus = await ss.customers.update(customerId, {
      //   invoice_settings: {
      //     default_payment_method: payment.id,
      //   },
      // });
      // console.log(`Customer>>>${cus}`);

      setLoading(false);


      setIsModalOpen(false)
      await fetchCards();




    } catch (error) {
      console.log("the error is ".error);
      setLoading(false);

      toast.error(`payment method is not added ${error}`);
    }

    return (
      <div>
        <button onClick={handleSubmit}>Make Payment</button>
      </div>
    );


  };



  //const { cards } = getCards(userData.customerId);
  const fetchCards = async () => {
    setLoading(true); // Set loading state to true
    const cardData = await getCards(userData.customerId);
    setCards(cardData); // Update state with fetched cards
    setLoading(false); // Set loading state to false
  };
  useEffect(() => {

    fetchCards();
  }, [userData.customerId]);
  useEffect(() => {
    // Update the defaultCard state whenever the cards change  
    const foundDefaultCard = cards.find(card => card.isDefault);
    setDefaultCardId(foundDefaultCard || null); // Set to found card or null if none
  }, [cards]);
  const handleCheckboxChange = (id) => {
    setDefaultCardId(id);
    const updatedCards = cards.map(card => ({
      ...card,
      isDefault: card.id === id,
    }));
    setCards(updatedCards);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (cardId) => {
    try {
      await deleteCard(userData.customerId, cardId); // Call the delete function with the card ID
      await fetchCards(); // Refresh the list after deletion
      toast.success("Card deleted successfully!");
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to delete card.");
    }
  };


  console.log(cards);
  const navigate = useNavigate()

  const handleSave = async () => {
    if (!defaultCardId) {
      toast.error("Please select a default card before saving.");
      return;
    }
    // Update cards state to set selected card as default and others as not default
    const updatedCards = cards.map((card) => ({
      ...card,
      isDefault: card.id === defaultCardId // Assuming card has a unique id
    }));
    setCards(updatedCards);// Update the state with new card values
    try {
      await saveCard(userData.customerId, defaultCardId.id);
      await fetchCards();
      toast.success("Default card saved successfully!");
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to save default card.");
    }

    console.log('Saved default card:', defaultCardId); // Log for debugging
  };
  console.log(defaultCardId);
  if (loading) {
    return (
      <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400">
        {/* Subtle Animated Background Effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 opacity-50 blur-2xl animate-pulse"></div>

        {/* Sleek Spinner */}
        <div className="relative z-10 rounded-full border-t-4 border-t-blue-500 border-4 border-gray-300 w-16 h-16 animate-spin glow-effect"></div>

        {/* Loading Text with Soft Animation */}
        <p className="relative z-10 text-gray-700 text-xl font-semibold mt-6 animate-fade-in tracking-wider">
          Loading...
        </p>
      </div>
    );
  }
  return (


    <div className='justify-items-center  bg-gray-200 bg-local min-h-screen h-screen mt-0'>
      <ToastContainer />
      <div onClick={() => navigate(-1)} className="absolute top-8 left-4 lg:left-12 cursor-pointer flex items-center hover:opacity-90 transition-opacity duration-300 ease-in-out">
        <button className="text-xl w-12 h-12 rounded-full   flex justify-center items-center ">
          {"<"}
        </button>
        <p className="ml-3 text-black text-lg font-semibold hover:underline ">Back</p>
      </div>
      <div className=''>
        <h1 className='text-xl font-bold  pt-8'>Payment Settings</h1>
      </div>
      <div>
        {loading ? ( // Conditional rendering based on loading state
          <p>Loading cards...</p>
        ) : (

          <div className='mt-8 space-y-2'>
            {cards.map((card, index) => ( // Mapping over the cards array
              <div key={index} className='bg-white rounded-md flex p-3 justify-between w-60'>
                <h1>{`${card.brandName} **** **** **** ${card.last4digits}`}</h1> {/* Adjust according to your card data structure */}
                {/* <input
                  type="checkbox"
                  checked={card.isDefault} // Set checkbox checked based on isDefault value
                  onChange={() => handleCheckboxChange(card.id)}
                /> */}
                <button
                  onClick={() => handleDelete(card.id)}
                  className="text-red-500 ml-2  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"  // Adjust size as needed
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 4H8l-1.5 14H19L21 4zM17 2l1 2H6l1-2zM4 6h16M10 11v6m4-6v6" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Add Card Button */}
        {/* <div className="mt-3 cursor-pointer" onClick={openModal}>

          <h1 className='bg-gray-400 h-10 w-24 text-white text-center pt-2'>{"+"} Add card</h1>
        </div> */}

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          > 
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-lg font-semibold mb-4">Add Card</h2>

              {/* Modal content here */}
              {showCardDetails && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </Elements>

              )}
              {!showCardDetails && (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-[#3ccad1] flex-row rounded-xl mt-4 w-full h-[52px] text-white font-semibold"
                  disabled={loading}
                >
                  {loading ? ( // Conditional rendering based on loading state
                    <p>Loading cards...</p>
                  ) : ("Add card")}
                </button>
              )}

            </div>
            <button
              onClick={closeModal}
              className="absolute  ml-60 -mt-24 text-gray-500 hover:text-gray-800"
            >
              Cancel
            </button>
          </div>
        )}
        {/* <div className='mt-4'>
          <button className='bg-[#3ccad1] w-full rounded-md text-white h-10'
            onClick={handleSave}
          >Save</button>
        </div> */}
      </div>
    </div>

  )
}
function CheckoutForm({ handleSubmit, loading }) {
  const stripe = useStripe();
  const elements = useElements();
  const isStripeLoaded = stripe && elements;
  return (
    <form onSubmit={(e) => handleSubmit(e, stripe, elements)}>

      <div className="mt-5">
        <CardElement />
        <button
          type="submit"
          disabled={!isStripeLoaded || loading}
          className="bg-[#3ccad1] rounded-xl mt-4 w-full h-[52px] text-white font-semibold"
        >
          {loading ? "Loading..." : "Add card"}
        </button>
      </div>
    </form>
  );
}

export default Payment
