import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, updatePassword } from "firebase/auth";
import profile from "../../../assets/icons/profile.png"
import './Adminupdate.css';

function Adminupdate() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth(); // Initialize Firebase auth

    const {userData } = location.state || {}; 
    console.log(userData);
    const [showForm, setShowForm] = useState(false); // To toggle password form visibility
    const [newPassword, setNewPassword] = useState(""); // State for new password
    const [error, setError] = useState(""); // Error state for feedback
    const [success, setSuccess] = useState(""); // Success state for feedback
  
    // Function to handle password change
    const handlePasswordChange = async (e) => {
      e.preventDefault();
      if (newPassword.length < 6) {
        setError("Password should be at least 6 characters long");
        return;
      }
  
      try {
        const user = auth.currentUser; // Get the currently logged-in user
        await updatePassword(user, newPassword); // Update the password
        setSuccess("Password updated successfully!");
        setError(""); // Clear any errors
        setNewPassword(""); // Clear the password input
      } catch (err) {
        setError(err.message); // Set the error message
        setSuccess(""); // Clear success message
      }
    };
    
  return (
    <div className="min-h-screen flex flex-col items-center justify-center   px-4 sm:px-6 lg:px-8 ">
            {/* Back Button */}
            <div onClick={() => navigate(-1)} className="self-start mt-2 ml-2 lg:ml-12 cursor-pointer flex items-center ">
                <button className="text-xl w-12 h-12 text-blue-600  flex justify-center items-center  ">
                    {"<"}
                </button>
                <p className="ml-2 text-black text-lg font-semibold hover:underline">Back</p>
            </div>

            {/* Profile Section */}
            <div className="mt-5 flex flex-col items-center animate-slide-in">
                <div className="relative w-40 h-40 bg-gray-400 rounded-full flex items-center justify-center shadow-xl ">
                    <img src={profile} alt="Profile" className="w-24 h-24 rounded-full object-cover shadow-md" />
                </div>
                <div className="text-center mt-3">
                    <h1 className="text-2xl font-bold text-black mt-4 ">{userData?.name}</h1>
                    <h2 className="text-2xl text-black mt-2">{userData?.email}</h2>
                </div>
            </div>

           
            {/* Password Change Form */}
            {showForm && (
                <div className="mt-12 w-full flex justify-center animate-slide-in">
                    <form onSubmit={handlePasswordChange} className="bg-white p-10 rounded-3xl shadow-2xl w-full max-w-lg scale-in-center">
                        <h3 className="text-2xl text-black font-semibold text-center mb-6">Update Your Password</h3>
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="border-2 border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600 transition-all duration-300 ease-in-out"
                        />
                        <button
                            type="submit"
                            className="bg-gray-400 text-white font-bold py-3 px-6 rounded-lg w-full mt-8 shadow-lg hover:bg-teal-600 hover:scale-105 transition-transform duration-300 ease-in-out"
                        >
                            Update Password
                        </button>

                        {/* Error and Success Messages */}
                        {error && <p className="text-red-500 mt-4 text-center animate-fade-in">{error}</p>}
                        {success && <p className="text-green-500 mt-4 text-center animate-fade-in">{success}</p>}
                    </form>
                </div>
            )}
             {/* Change Password Button */}
             <div className="mt-10 animate-slide-in mb-5">
                <button
                    onClick={() => setShowForm(!showForm)}
                    className=" from-white bg-gray-300  text-teal-900 font-bold py-3 px-12 rounded-full shadow-lg  hover:scale-110 hover:from-teal-600 hover:to-green-600  "
                >
                    {showForm ? "Cancel" : "Change Password"}
                </button>
            </div>

        </div>
  )
}

export default Adminupdate
