import { useEffect, useState } from "react";

export function useDebouncSearch(defaultState, delay = 1000) {
  const [search, setSearch] = useState(defaultState);
  const [searchQuery, setSearchQuery] = useState(defaultState);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), 1);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  const setSearchQueryWithPreviousValue = (value) => {
    setSearchQuery((prevValue) => {
      if (typeof value === "function") {
        return value(prevValue);
      }
      return value;
    });
  };

  return [search, setSearchQueryWithPreviousValue];
}
