import React, { useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import chair from '../../assets/icons/chair.png'
import box2 from '../../assets/icons/box2.png'
import { Link, useNavigate} from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { app } from '../../firebase'

function Quote() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate();
    const auth = getAuth(app);
   // const navigate = useNavigate();

    
  useEffect(() => {
  const user = auth.currentUser;
  setIsLoggedIn(!!user)
  }, [auth])

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };
  return (
    <div className='bg-gray-100 min-h-screen xl:h-screen'>
      <div className='p-4 px-padding flex flex-row justify-between border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
            <div className='mt-3'>
                <img src={Homeogo} width={36} height={36} 
                className='' alt=''/>
               
                
            </div>
           
            <div className='flex flex-row gap-2'>
                <p className='text-5xl'>|</p>
             <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
            </div>
        </div>
        {!isLoggedIn&&(

        <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
        <Link to="/login"><button className='bg-[#3ccad1] rounded-xl  hidden sm:block w-40 font-aeroport font-normal h-14'>Iniciar Sesión</button></Link>
        </div>
        )}
            
        <nav className=" sm:hidden">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              id="mobile-menu-button"
              className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={toggleMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              {/* Hamburger menu icon */}
              <svg
                className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              {/* Close menu icon */}
              <svg
                className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}  id="mobile-menu">
        <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
        <Link to="/login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Iniciar Sesión</button>
        </Link> </div>
      </div>
    </nav>
    </div>
    <div className='flex flex-row justify-between'>
    <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-6 h-6">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>
    </div>
    <div   className='flex flex-col items-center mt-10 '>
    
        <div>
        <h2 className='text-4xl text-center font-semibold font-aeroport'>¿Cómo prefieres cotizar?</h2>
        </div>
        <div className='flex lg:flex-row flex-col gap-10 mx-auto mt-16 text-center'>
           
        <div onClick={toggleModal} className='w-80 h-80 border-2 content-center bg-white rounded-3xl px-8'
        >
            <img src={chair} alt="chair"
            className='w-28 h-28 mx-auto ' />
            
            <p className='text-xl font-semibold font-aeroport mt-6'>Lo haré utilizando el calculador de espacio</p>
        </div>
       
        <Link to={"/estimatedvalue"}>
        <div className='w-80 h-80 border-2 bg-white content-center rounded-3xl px-8'>
            <img src={box2} alt="chair"
            className='w-28 h-28 mx-auto ' />
            
            <p className='text-xl font-semibold font-aeroport mb-6 mt-6'>Quiero elegir un plan</p>
        </div></Link>

        

        </div>
    </div>
      {isModalOpen && (
        <div className=" relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " aria-hidden="true"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex flex-col sm:items-start">
                  
                    <div className="mt-3 flex flex-col sm:flex-row gap-9 text-center items-center sm:ml-4 sm:mt-0 ">
                  
                      <div className="mt-2 text-center  w-1/2">
                        <p className="text-sm text-gray-500 font-aeroport font-semibold">Haz clic aquí si sabes qué vas a almacenar</p>
                        <Link to={"/addedqoute"}>
                        <button  type="button" className="inline-flex mt-3 w-full justify-center bg-[#3ccad1] rounded-xl px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Haz clic aquí</button>
                      </Link>
                      </div>
                      <div className="mt-2 text-center w-1/2 p-2  border-l-2 border-gray-100">
                        <p className="text-sm text-gray-500 font-aeroport font-semibold"> Quiero ayuda personalizada.</p>
                        <a
                                               // href="https://api.whatsapp.com/send?phone=+525586168420"
                                                 href="https://wa.me/525586168420"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <button className="inline-flex text-center mt-3 w-full justify-center bg-[#3ccad1] rounded-xl px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700">
                                                    Chat on WhatsApp
                                                </button>
                                            </a>
                      </div>
                    </div>
                    <div className='mx-auto mt-8' >

                   
                    <button onClick={toggleModal} type="button" className="inline-flex text-center  mt-3 w-full justify-center  bg-[#3ccad1] rounded-xl px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Cancelar</button>
                   </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      )}
      
    </div>
    
  )
}

export default Quote