import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getFirestore, setDoc, doc } from 'firebase/firestore'; // Import Firestore functions
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'; // Import Firebase Auth functions
import { app } from "../../firebase";


function Signup() {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const navigate = useNavigate();

    const auth = getAuth(app);
  const firestore = getFirestore(app);

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!name) {
        toast.error("Please enter name");
        return;
      } else if (!lastName) {
        toast.error("Please enter Lastname");
        return;
      } else if (!email) {
        toast.error("Please enter email");
        return;
      } else if (!phone) {
        toast.error("Please enter your Phone Number");
        return;
      } else if (!postalCode) {
        toast.error("Please enter your postalCode Number");
        return;
      }

    try {
        // Create user in Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Save user data in Firestore under the Users collection
        await setDoc(doc(firestore, 'Users', user.uid), {
            email: email,
            name: name,
            lastName: lastName,
            phone: phone,
            postalCode: postalCode,
            uid: user.uid,
        });

        // Show success message
        toast.success('User registered successfully!');

        // Navigate to the login page after successful signup
        navigate('/login');
    } catch (error) {
        toast.error(error.message);
    }
};

  
    return (
        <div className="bg-gray-100 min-h-screen">
           <ToastContainer />
         
          <div
            onClick={() => navigate(-1)}
            className=" flex flex-row mt-3  sm:mx-32 mx-12"
          >
            <button className="text-xl mt-1 w-7 h-7">{"<"}</button>
            <p className="mt-2">Regresar</p>
          </div>  
          <div className="flex flex-col items-center mt-12  ">
            <div className="px-4">
              <h2 className="text-4xl text-center  font-semibold font-aeroport">
              Por favor, ingrese sus datos para crear una cuenta.
              </h2>
            </div>
            <div className="flex flex-row gap-10 mx-auto mt-5 ">
              <div className="lg:w-[870px] w-auto lg:h-[473px] bg-white h-auto border-2 rounded-3xl sm:p-6 p-[18px] py-7 mb-7">
                <form>
                  <div className="space-y-12">
                    <div className=" border-gray-900/10 pb-12">
                      <div className="mt-10 grid grid-cols-1 sm:gap-x-6 gap-x-4 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                            Dirección de correo electrónico
                          </label>
                          <div className="mt-2">
                            <input
                              // value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              required
                              className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                            Nombres
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              className="block p-1 w-full bg-gray-200 rounded-md  py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                              // value={
                              //   userData && userData.name ? userData.name : name
                              // }
                              //
                              //disabled={userData && userData.name ? "disabled" : ""}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
    
                        <div className="sm:col-span-3">
                          <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                            Apellidos
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              value={lastName}
                             
                             
                              onChange={(e) => setLastName(e.target.value)}
                              required
                              className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
    
                        {/* {!emailExists && ( */}
                          <div className="sm:col-span-3">
                            <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                              Contraseña
                            </label>
                            <div className="mt-2">
                              <input
                                type="password"
                                className="block p-1 w-full bg-gray-200 rounded-md py-1.5 text-gray-900 shadow-sm ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Contraseña"
                              />
                            </div>
                          </div>
                        {/* // )} */}
    
                        <div className="sm:col-span-3">
                          <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                            TELÉFONO
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              value={phone}
                              
                             
                              onChange={(e) => setPhone(e.target.value)}
                              required
                              className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">
                            Código Postal
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              
                              value={postalCode}
                             
                              onChange={(e) => setPostalCode(e.target.value)}
                              required
                              className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div className="mt-2 flex items-center justify-center gap-x-6">
                    <button
                      onClick={handleSignup}
                      type="submit"
                      className="text-sm  font-semibold font-aeroport leading-6 w-72 h-14 rounded-2xl bg-[#3ccad1] text-white mb-8"
                    >
                      Crear cuenta.
                    </button>
                  </div>
                </form>
               
              </div>
            </div>
          </div>
        </div>
      );
}

export default Signup
