import React from 'react'
import Convience from '../nanopages/Convience'

function Ultimate() {
  return (
    <div>
       
        <Convience/>
    </div>
  )
}

export default Ultimate