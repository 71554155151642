
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Bform from './components/auth/Bform';
import Control from './components/auth/Control';
import Form from './components/auth/Form';
import Infoprofile from './components/auth/Infoprofile';
import Login from './components/auth/Login';
import Request from './components/auth/Request';
import Dashboard from './components/backend/Dashboard';
import Dashhome from './components/backend/Dashhome';
import Adminupdate from './components/backend/menu/Adminupdate';
import Dashboardmenu from './components/backend/menu/Dashboardmenu';
import Dashboardrequest from './components/backend/menu/Dashboardrequest';
import Userlisting from './components/backend/menu/Userlisting';
import Warehouse from './components/backend/menu/Warehouse';
import Addedquote from './components/innerpages/Addedquote';
import Flexible from './components/innerpages/Flexible';
import Quote from './components/innerpages/Quote';
import Recommend from './components/innerpages/Recommend';
import Savemore from './components/innerpages/Savemore';
import { SelectedItemsProvider } from './components/innerpages/SelectedItemsContext';
import Selfquote from './components/innerpages/Selfquote';
import Show from './components/innerpages/Show';
import Ultimate from './components/innerpages/Ultimate';
import Estimated from './components/nanopages/Estimated';
import Finalestimated from './components/nanopages/Finalestimated';
import Priceestimated from './components/nanopages/Priceestimated';
import Textareestimated from './components/nanopages/Textareestimated';
import Valueestimated from './components/nanopages/Valueestimated';
import Home from './components/pages/Home';
import Allroute from './components/screen/Allroute';
import Signup from './components/auth/Signup';
import Submition from './components/nanopages/Submition';
import TotalUser from './components/backend/menu/TotalUser';
import Payment from './components/auth/Payment';




function App() {
  return (
    <SelectedItemsProvider>
    <BrowserRouter>
    <Routes>
    <Route  path='/' element={<Allroute/>}/>
      <Route  path='/home' element={<Home/>}/>
      <Route  path='/selfquote' element={<Selfquote/>}/>
      <Route  path='/addedqoute' element={<Addedquote/>}/>
      <Route  path='/recomend' element={<Recommend/>}/>
      <Route  path='/form' element={<Form/>}/>
      <Route  path='/profileinfo' element={<Infoprofile/>}/>
      <Route  path='/bform' element={<Bform/>}/>
      <Route  path='/show' element={<Show/>}/>
      <Route  path='/quote' element={<Quote/>}/>
      <Route  path='/savemore' element={<Savemore/>}/>
      <Route  path='/ultimate' element={<Ultimate/>}/>
      <Route  path='/flexible' element={<Flexible/>}/>
      <Route  path='/estimated' element={<Estimated/>}/>
      <Route  path='/estimatedvalue' element={<Valueestimated/>}/>
      <Route  path='/estimatedprice' element={<Priceestimated/>}/>
      <Route  path='/finalestimated' element={<Finalestimated/>}/>
      <Route  path='/login' element={<Login/>}/>
      <Route  path='/dashboard' element={<Dashboard/>}/>
      <Route  path='/dashboardmenu' element={<Dashboardmenu/>}/>
      <Route  path='/userlisting' element={<Userlisting/>}/>
      <Route  path='warehouse' element={<Warehouse/>}/>
      <Route  path='/estimatetextarea' element={<Textareestimated/>}/>
      <Route  path='/control' element={<Control/>}/>
      <Route  path='/dashhome' element={<Dashhome/>}/>
      <Route  path='/request' element={<Request/>}/>
      <Route  path='/dashboardrequest' element={<Dashboardrequest/>}/>
      <Route  path='/adminupdate' element={<Adminupdate/>}/>
      <Route  path='/signup' element={<Signup/>}/>
      <Route  path='/submition' element={<Submition/>}/>
      <Route  path='/totaluser' element={<TotalUser/>}/>
      <Route  path='/payment' element={<Payment/>}/>


      <Route  path='/*' element={<h1>Page not found</h1>}/>

    </Routes>
    </BrowserRouter>
    </SelectedItemsProvider>
    
  );
}

export default App;
