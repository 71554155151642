import React, { useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom';
//import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { collection, getDocs, getFirestore, query,  where } from 'firebase/firestore';
import {app} from "../../firebase"
const firestore = getFirestore(app);
function Bform() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { selectedItems } = location.state || { selectedItems: {}};
  const { totalVolumeUsed } = location.state || {};
  console.log(selectedItems);
  console.log(totalVolumeUsed);
  //const { user } = location.state || {};
 // console.log(user);
 
 
  
 useEffect(() => {
    const fetchUserData = async () => {
        if (email) {
            setLoading(true);
            const usersCollection = collection(firestore, 'Users');
            const q = query(usersCollection, where('email', '==', email));

            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    setUserData(userDoc.data());
                } else {
                    setUserData(null);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
    };

    fetchUserData();
}, [email]);
    
    
    
  
    console.log(userData);
  
  const handleButtonClick = () => {
    if (!name) {
      alert('Please enter name ');
      return;
    }else if(!lastName){
      alert('Please enter Lastname ');
      return;
    }
    else if(!email){
      alert('Please enter email ');
      return;
    }
    else if(!phone){
      alert('Please enter your Phone Number');
      return;
    }
    else if(!postalCode){
      alert('Please enter your postalCode Number');
      return;
    }
    const containsSpecialItem = Object.values(selectedItems).some(item => item.category === "dxa9HzgOxsWcqnrhv77s");

    if (containsSpecialItem) {
      navigate('/estimatetextarea', { state: { selectedItems,totalVolumeUsed,userData} });  // Replace with your desired path for "bnsasba"
    } else {
      navigate('/estimatedprice', { state: { selectedItems,totalVolumeUsed,userData } });  // Replace with your desired path for others
    }
  };
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

   // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    return (
      <div className='bg-gray-100 lg:h-[850px]'>
     <div className='p-4 flex flex-row justify-between border-b-2'>
          <div className='flex flex-row gap-2 justify-items-center'>
              <div className='mt-3'>
                  <img src={Homeogo} width={36} height={36} 
                  className='' alt=''/>
                 
                  
              </div>
             
              <div className='flex flex-row gap-2'>
                  <p className='text-5xl'>|</p>
               <h2 className=' text-3xl mt-2'>Kiip</h2>
              </div>
          </div>
          
          <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
          <Link to="/login"><button className='bg-[#3ccad1] rounded-xl  hidden sm:block w-[155px] font-aeroport font-normal h-[52px]'>Iniciar sesión</button></Link>
          </div>
              
          <nav className=" sm:hidden">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                id="mobile-menu-button"
                className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={isMenuOpen ? "true" : "false"}
                onClick={toggleMenu}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                {/* Hamburger menu icon */}
                <svg
                  className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
                {/* Close menu icon */}
                <svg
                  className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}  id="mobile-menu">
          <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
          <Link to="/login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Iniciar sesión</button>
          </Link>  </div>
        </div>
      </nav>
      </div>
  
    
  <div className='flex flex-col items-center mt-12  '>
      <div className='px-[16px]'>
      <h2 className='text-4xl text-center  font-semibold font-aeroport'>Déjanos tus datos para que podamos enviarte tu cotización</h2>
      </div>
      <div className='flex flex-row gap-10 mx-auto mt-5 '>
          
      <div className='lg:w-[870px] w-auto lg:h-[473px] bg-white h-auto border-2 rounded-3xl sm:p-[25px] p-[18px] py-[29px] mb-7'>
      <form >
    <div className="space-y-12">
     
  
      <div className=" border-gray-900/10 pb-12">
        <div className="mt-10 grid grid-cols-1 sm:gap-x-6 gap-x-4 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label  className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">Nombres</label>
            <div className="mt-2">
              <input type="text" className="block p-1 w-full bg-gray-200 rounded-md  py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              />
            </div>
          </div>
  
          <div className="sm:col-span-3">
            <label  className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">Apellidos</label>
            <div className="mt-2">
              <input type="text" 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
               className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"/>
            </div>
          </div>
  
          <div className="sm:col-span-6">
            <label className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">Dirección de correo electrónico</label>
            <div className="mt-2">
              <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required 
               className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label  className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">TELÉFONO</label>
            <div className="mt-2">
              <input type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
               className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label  className="block text-sm font-semibold font-aeroport leading-6 text-gray-900">Código Postal</label>
            <div className="mt-2">
              <input type="text" 
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              required
              className="block w-full p-1 rounded-md bg-gray-200 py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"/>
            </div>
          </div>
        </div>
      </div>
  
      
    </div>
  
    <div className="mt-2 flex items-center justify-center gap-x-6">
   
      <button onClick={handleButtonClick} type="submit" className="text-sm  font-semibold font-aeroport leading-6 w-[289px] h-[52px] rounded-2xl bg-[#3ccad1] text-white mb-8">VER MI COTIZACIÓN</button>
      
       </div>
  </form>
          
      </div>
      </div>
  </div>
  </div>
    )
  }
export default Bform
