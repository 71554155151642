import React  from 'react'
import Navbar from '../shared/Navbar'
import {Link} from 'react-router-dom'
import home2 from "../../assets/home2.png"

function Home() {
  return (
        <div className='background2 '>
            <Navbar />
         <div className='px-padding'>
            <div className=' text-center space-y-7 md:mt-16 mt-5 pb-16 text-black p-1  '>
             <h2 className='text-center  md:text-5xl sm:text-4xl text-2xl font-aeroport font-bold  '>Tu mini almacén sin salir de casa,<br/> con un solo click </h2>
            <p className='text-center text-xs sm:text-2xl font-aeroport text-[#21A3AA] font-normal '>Nos encargamos de todo por ti. <br/>Recogemos, almacenamos y entregamos<br/>tus pertenencias siempre que las necesites.</p>
            <Link to="quote">
            <button className='bg-[#3ccad1] mt-9 w-48 h-12
             justify-center font-aeroport font-normal text-white rounded-xl
             '>Cotiza tu espacio</button></Link>
            </div>

            <img src={home2} alt=""
            className='lg:width lg:height min-w-auto mx-auto' />
        </div>

    </div>
  )
}

export default Home