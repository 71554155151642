import React, { useState } from "react";
import Dashboardnavigation from "./Dashboardnavigation";
import Dashboardtable from "./Dashboardtable";
import Dashnavbar from "./Dashnavbar";

function Dashboardmenu() {
  const [showPending, setShowPending] = useState(false);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Dashnavbar />
      <Dashboardnavigation
        onClick={(value) => {
          console.log("new value", value);
          setShowPending(value);
        }}
      />
      <Dashboardtable showPending={showPending} />
    </div>
  );
}

export default Dashboardmenu;
