import React, { useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';
//import { addDoc, collection, getFirestore } from 'firebase/firestore';
//import { app } from '../../firebase';


//const firestore = getFirestore(app)
const auth = getAuth(app)
function Textareestimated() {
  const location = useLocation();
  const { selectedItems} = location.state || { selectedItems: {}};
  const userData = location.state?.userData || location.state?.user || {};
  const { totalVolumeUsed } = location.state || {};
  console.log(selectedItems);
  console.log(userData);
  console.log(totalVolumeUsed);
  const volume = parseFloat((totalVolumeUsed).toFixed(2));
  let result;
  if (totalVolumeUsed > 0 && totalVolumeUsed <= 1.8) {
    result = parseFloat((349.00).toFixed(2));
  } else if (totalVolumeUsed > 1.8 && totalVolumeUsed <= 3.6) {
    result = parseFloat((698.00).toFixed(2));
  }
  else if (totalVolumeUsed > 3.6 && totalVolumeUsed <= 5.4) {
    result = parseFloat((1047.00).toFixed(2));
  }
  else if (totalVolumeUsed > 5.4 && totalVolumeUsed <= 7.2) {
    result = parseFloat((1396.00).toFixed(2));
  }
  else if (totalVolumeUsed > 7.2 && totalVolumeUsed <= 9) {
    result = parseFloat((1745.00).toFixed(2));
  }
  else if (totalVolumeUsed > 9 && totalVolumeUsed <= 10.8) {
    result = parseFloat((2094.00).toFixed(2));
  }
  else if (totalVolumeUsed > 10.8 && totalVolumeUsed <= 12.6) {
    result = parseFloat((2443.00).toFixed(2));
  }
  else if (totalVolumeUsed > 12.6 && totalVolumeUsed <= 14.4) {
    result = parseFloat((2792.00).toFixed(2));
  }
  else if (totalVolumeUsed > 14.4 && totalVolumeUsed <= 16.2) {
    result = parseFloat((3141.00).toFixed(2));
  }
  else if (totalVolumeUsed > 16.2 && totalVolumeUsed <= 18) {
    result = parseFloat((3490.00).toFixed(2));
  }

  const navigate = useNavigate(); 
  const [textareaValue, setTextareaValue] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [randomNumbers, setRandomNumbers] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [boxDetails, setBoxDetails] = useState([]); 
    const [language, setLanguage] = useState('es');
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    
    const generateRandomNumber = () => {
      return Math.floor(100000 + Math.random() * 900000); // Generates a random 6-digit number
    };
    useEffect(() => {
    const user = auth.currentUser;
    setIsLoggedIn(!!user)
    }, [auth])


    // Generate random numbers only once when the component mounts
  useEffect(() => {
    const randomNumbers = {};
    Object.keys(selectedItems).forEach((itemId) => {
      const category = selectedItems[itemId].category;
      const quantity = selectedItems[itemId].quantity;
      if (category === "dxa9HzgOxsWcqnrhv77s") {
        if (!randomNumbers[itemId]) {
          randomNumbers[itemId] = [];
        }
        for (let i = 0; i < quantity; i++) {
          randomNumbers[itemId].push(generateRandomNumber());
        }
      }
    });
    setRandomNumbers(randomNumbers); // Store the generated numbers in state
  }, [selectedItems]);

  const handleInputChange = (itemId, idx, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [itemId]: {
        ...prevValues[itemId],
        [idx]: value,
      }
    }));

    // Update the box details
    setBoxDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      const existingIndex = updatedDetails.findIndex(detail => detail.itemId === itemId && detail.index === idx);

      const newDetail = {
        itemId,
        index: idx,
        boxName: selectedItems[itemId].es_name,
        boxCode: randomNumbers[itemId]?.[idx] || generateRandomNumber(),
        boxContent: value
      };

      if (existingIndex >= 0) {
        updatedDetails[existingIndex] = newDetail;
      } else {
        updatedDetails.push(newDetail);
      }

      return updatedDetails;
    });
  };

    const handleSubmit = async (e) => {
      e.preventDefault();


console.log("Input values:", inputValues);

    

// Validation: Check if all input fields in the selected items are filled
for (let itemId in selectedItems) {
  const item = selectedItems[itemId];
  
  if (item.category === "dxa9HzgOxsWcqnrhv77s") {
      for (let idx = 0; idx < item.quantity; idx++) {
          const value = inputValues[itemId]?.[idx];
          if (!value || value.trim() === '') {
            toast.error(`Please enter the contents of the box for the item: ${selectedItems[itemId].es_name} (Index ${idx + 1})`);

              return;
          }
      }
  }
}


      // const randomNumbers = {};
      // Object.keys(selectedItems).forEach((itemId) => {
      //   const category = selectedItems[itemId].category;
      //   const quantity = selectedItems[itemId].quantity;
      //   if (category === "dxa9HzgOxsWcqnrhv77s") {
      //     if (!randomNumbers[itemId]) {
      //       randomNumbers[itemId] =[];
      //     }
          
      //     for (let i = 0; i < quantity; i++) {
      //       randomNumbers[itemId].push(generateRandomNumber());
      //     }
      //   }
      // });
  
    console.log(selectedItems);
    console.log(randomNumbers);
    const randomNumbersList = Object.keys(randomNumbers).length > 0 ? Object.values(randomNumbers) : ["no box is selected"];
    const createdAt = new Date();
    //const userId = userData.uid
  console.log("Random Numbers Generated:", randomNumbersList);
  navigate("/estimatedprice", { state: {selectedItems, randomNumbersList, createdAt,textareaValue,totalVolumeUsed,userData, boxDetails  } });
  
 
   
      
    };

   

  return (
    <div>
      <div className='p-1'>
      <ToastContainer />
       <div className='p-4 flex flex-row justify-between border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
            <div className='mt-3'>
                <img src={Homeogo} width={36} height={36} 
                className='' alt=''/>
               
                
            </div>
           
            <div className='flex flex-row gap-2'>
                <p className='text-5xl'>|</p>
             <h2  translate='no' className=' text-3xl mt-2'>Kiip</h2>
            </div>
        </div>
        {!isLoggedIn&&(

        <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
        <Link to="/login"><button className='bg-[#3ccad1] rounded-xl  hidden sm:block w-[155px] font-aeroport font-normal h-[52px]'>Iniciar sesión</button></Link>
        </div>
        )}
            
        <nav className=" sm:hidden">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              id="mobile-menu-button"
              className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={toggleMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              {/* Hamburger menu icon */}
              <svg
                className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              {/* Close menu icon */}
              <svg
                className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}  id="mobile-menu">
        <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
        <Link to="/login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Iniciar sesión</button>
        </Link></div>
      </div>
    </nav>
    </div> 
    
    <div onClick={() => navigate(-1)}  className=' flex flex-row mt-3 sm:mx-32 mx-12'>
    <button className="text-xl mt-1 w-[26px] h-[26px]">{"<"}</button>
        <p className='mt-2'>Regresar</p>
    </div>
      <div className='items-center mx-auto sm:w-[700px]  '>

     <div className='flex sm:flex-row flex-col gap-4 justify-center items-center'>
     <div className=' justify-between flex sm:w-[315px] rounded-lg w-[250px] sm:h-[110px] border-2 mt-3 items-center px-[17px]'>
            <div className='text-center mx-auto'>
                <h1 className='font-semibold text-[#21A3AA]'>Colección inicial</h1>
                <h1 className='font-bold'>Volumen seleccionado.</h1>
                <p className='font-semibold'>{volume}m³</p>
                
            </div>
        </div>
        <div className=' justify-between flex sm:w-[315px] bg-[#21A3AA] rounded-lg w-[250px] sm:h-[110px] border-2 mt-3 items-center px-[17px]'>
            <div className='text-center text-white mx-auto'>
                <h1 className='font-semibold  text-4xl mt-1'>{result}</h1>
                <h1 className='font-extralight text-xs'>MXN/Mes</h1>
                <p  className='font-semibold text-md'>por mes.</p>
                <p className='font-extralight text-xs'>Máxima flexibilidad, sin compromiso de permanencia.</p>
            </div>
        </div>
     </div>
     <div className='font-aeroport p-7 pb-1'>
        <h1 className='font-semibold text-xl'>¿Qué hay dentro de la caja?</h1>
        <p className='font-normal  mt-2'>Describe los artículos que están dentro de la caja</p>
        <p className='font-normal  mt-2'> Por favor, toma nota del código de tu caja
        Por caja.
</p>
     </div>


   {/* Display selected items and their generated box codes */}
  
   <div className='p-[25px] py-[29px] mb-7'>
          <ul className="border border-gray-300 rounded-lg p-4 bg-white">
            {Object.keys(selectedItems).map((itemId) => {
              const item = selectedItems[itemId];
              if (item.category === "dxa9HzgOxsWcqnrhv77s") {
                return (
                  <li key={itemId} className="mb-4 border-b  pb-2">
                    <span className="font-semibold block text-lg mb-2">Box</span>
                    <ul className="pl-6">
                      {Array.from({ length: item.quantity }).map((_, idx) => (
                        <li key={idx} className="p-4 py-1 bg-gray-100 rounded-md mb-1">
                         <span className='mr-2 font-bold'> Name:{item.es_name}</span> Box code: {randomNumbers[itemId]?.[idx] || generateRandomNumber()}
                          <textarea
                            type="text"
                            className="block p-2 w-full font-semibold text-xl rounded-md mt-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300"
                            placeholder="Describe what's inside the box?"
                            value={inputValues[itemId]?.[idx] || ''}
                            onChange={(e) => handleInputChange(itemId, idx, e.target.value)}
                            rows="3"
                          />
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>


     
        
    <div className='p-[25px] py-[29px] mb-7'>
    <form>
    <div className="  pb-12">
           <div className="mt-2">
           <textarea id="about" name="about" rows="9" className="block w-full font-semibold text-xl rounded-md  py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  p-3  sm:leading-6" placeholder='comentarios adicionales...' 
           value={textareaValue}
           onChange={(e) => setTextareaValue(e.target.value)}></textarea>
           </div>
    </div>  
  <div className="mt-2 flex items-center justify-center gap-x-6">
    <button onClick={handleSubmit} type="submit" className="text-sm font-semibold font-aeroport leading-6 w-[289px] h-[52px] rounded-2xl bg-[#3ccad1] text-white">Agendar</button>
     </div>
</form>
        
    </div>
   
      </div>

    </div>
    </div>
  )
}

export default Textareestimated
