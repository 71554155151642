import React, { useEffect } from 'react'
import submition from '../../assets/submition.png'
import { useLocation, useNavigate } from 'react-router-dom';

function Submition() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData } = location.state || {};
console.log(userData);
const user = userData

  useEffect(() => {
    // Set a timer for 2 seconds to navigate to the control screen
    const timer = setTimeout(() => {
      navigate("/control",{ state: {user } }); // Replace '/control' with the path to your control screen
    }, 2000);

    // Clear timer if component unmounts early
    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <div className=" h-screen  flex flex-col items-center justify-center">
        
      <img src={submition}  className="sm:max-w-xl max-w-xs" alt="" />
       
      <div className=" flex items-center justify-center  bg-opacity-50 mt-6">
        <h1 className=" text-xl font-bold">Your collection  has been submitted </h1>
      </div>
    </div>
  )
}

export default Submition
