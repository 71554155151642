import React, {useEffect, useState } from 'react'
import Homeogo from "../../assets/icons/logo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';

function Recommend() {
  const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const { selectedItems } = location.state || { selectedItems: {}};
  //const { user, userData} = location.state || {};
 const auth = getAuth(app);
 // console.log(user);
  //console.log(userData);
  
  useEffect(() => {
    // Check if the user is logged in
    const user = auth.currentUser;
    setIsLoggedIn(!!user);
  }, [auth]);
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    // Calculator logic
  const roundNumber = (number) => {
    return parseFloat(number.toFixed(2));
  };

  const calculateTotalVolume = (items) => {
    return items.reduce((total, item) => {
      const volume =roundNumber((item.depth / 100) * (item.width / 100) *(item.height / 100) * item.quantity);
      return total + volume;
    }, 0);
  };

  const calculateTotalWeight = (items) => {
    return items.reduce((total, item) => {
      const weight = roundNumber(item.weight * item.quantity);
      return total + weight;
    }, 0);
  };

  const totalVolumeUsed = calculateTotalVolume(Object.values(selectedItems));
  const totalWeight = calculateTotalWeight(Object.values(selectedItems));
  //const spakioStorage = 12.0; // in m²
  const totalSpace = 30.0; // in m³


  const handleFormClick = () => {
    navigate('/form', { state: { selectedItems,totalVolumeUsed,totalWeight} });

    // const user = auth.currentUser;
    // if (user) {
    //   // If the user is already signed in, navigate to the login screen
    //   navigate('/form', { state: { selectedItems,totalVolumeUsed,totalWeight} });
    // } else {
    //   // If the user is not signed in, navigate to the signup form
    //   navigate('/form', { state: { selectedItems,totalVolumeUsed,totalWeight} });
    // }
  };


 

  return (
    <div className='bg-gray-100 min-h-screen max-h-full'>
     <div className='p-4 flex flex-row justify-between border-b-2'>
        <div className='flex flex-row gap-2 justify-items-center'>
            <div className='mt-3'>
                <img src={Homeogo} width={36} height={36} 
                className='' alt=''/>
               
                
            </div>
           
            <div className='flex flex-row gap-2'>
                <p className='text-5xl'>|</p>
             <h2 translate='no' className=' text-3xl mt-2'>Kiip</h2>
            </div>
        </div>
        {!isLoggedIn && (

        <div className='flex flex-row gap-3 text-white font-semibold text-xl justify-items-center'>
        <Link to="/login"><button className='bg-[#3ccad1] rounded-xl  hidden sm:block w-40 font-aeroport font-normal h-12'>Iniciar sesión</button></Link>
        </div>
        )}
            
        <nav className=" sm:hidden">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              id="mobile-menu-button"
              className="relative inline-flex items-center justify-center rounded-md  text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={toggleMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              {/* Hamburger menu icon */}
              <svg
                className={`block h-6 w-6 ${isMenuOpen ? 'hidden' : 'block'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              {/* Close menu icon */}
              <svg
                className={`h-6 w-6 ${isMenuOpen ? 'block' : 'hidden'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}  id="mobile-menu">
        <div className="space-y-1 px-2 pt-2 pb-3 bg-gray-800">
        <Link to="/login"> <button className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white" aria-current="page">Iniciar sesión</button>
        </Link> </div>
      </div>
    </nav>
    </div>
    <div
        onClick={() => navigate(-1)}
        className=" flex flex-row mt-3 sm:mx-32 mx-12"
      >
        <button className="text-xl mt-1 w-7 h-7">{"<"}</button>
        <p className="mt-2">Regresar</p>
      </div>  
  
<div className='flex flex-col items-center mt-24 '>
    <div>
    <h2 className='text-4xl text-center  font-semibold font-aeroport'>Recomendamos</h2>
    </div>
    <div className='flex md:flex-row flex-col gap-10 mx-auto mt-8 sm:px-4 px-1 '>
    
        <div className='flex flex-col  mt-3 bg-white h-auto sm:px-3 px-1'>
        <div className='border-b-2 border-gray-200 mt-3'>
            <h2 className='text-3xl text-center md:text-left font-semibold font-aeroport mb-3'>Artículos Agregados</h2>
        </div>
        <div className='flex flex-col sm:gap-4 gap-2 items-center rounded-xl mt-4 bg-white    h-96 overflow-scroll md:w-fit p-0  sm:p-2 mb-3 '>
        {Object.values(selectedItems).map((item, index) => (
          <div key={item.es_name} className="bg-[#F1F1F1] gap-4 mt-3 sm:p-3 p-1 items-center flex flex-row sm:w-96 w-72 rounded-2xl">
            <div>
              <h3 className='bg-[#3ccad1] w-8 h-8 text-center   rounded-full pt-1 text-white'>{item.quantity}</h3>
            </div>
            <div>
              <div className='hidden lg:flex  flex-col'>
                <div className='flex flex-row  gap-2 font-aeroport'>
                  <p className="font-semibold text-xs">{item.es_name}</p>
                  
                  <p className="font-semibold text-xs"> Weight {item.weight}kg</p>
                </div>
                <div className='flex flex-row gap-2 mt-2'>
                  <p className="font-semibold text-xs">Width:{item.width}cm</p>
                <p className=" font-semibold text-xs ">Depth:{item.depth}cm</p>
                  <p className="font-semibold text-xs">Height:{item.height}cm</p>
                </div>
              </div>
              <div className='lg:hidden'>
                <div className='grid sm:grid-cols-3 grid-cols-2  gap-2  font-aeroport'>
                  <p className="font-semibold text-xs">{item.es_name}</p>
                  
                  <p className="font-semibold text-xs"> Weight {item.weight}kg</p>
                </div>
                <div className='grid sm:grid-cols-3 grid-cols-2 gap-2 mt-2'>
                  <p className="font-semibold text-xs">Width:{item.width}cm</p>
                <p className=" font-semibold text-xs ">Depth:{item.depth}cm</p>
                  <p className="font-semibold text-xs">Height:{item.height}cm</p>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        </div>
        <div className='flex flex-col mt-3 mx-auto :mx-0'>
        <div className='sm:w-80 w-full sm:h-36  h-auto bg-[#21A3AA] rounded-xl font-aeroport font-semibold text-white p-2 px-3'>
                  <h2 className='text-lg'>Storage 1{}</h2>
                  <h2 className='text-md'>1m2{}</h2>                   {/**Added items are here to add */}
                  <h2 className='text-md'>Space: {totalSpace} m³ | Used: {totalVolumeUsed.toFixed(2)} m³ | </h2>
                  <h2 className='text-md'>Weight: {totalWeight.toFixed(2)} kg</h2>
        </div>
        <div className='md:text-right mt-5'>
            
        <button onClick={handleFormClick} className='bg-[#21A3AA] w-80   h-14 rounded-2xl text-white  font-aeroport'>SOLICITAR COTIZACIÓN</button>
        </div>
        </div>
    </div>
</div>
</div>
  )
}

export default Recommend