import axios from 'axios';
const BASE_URL = 'https://api.stripe.com';

const FIREBASE_BASE_URL = 'https://us-central1-kiip-51772.cloudfunctions.net/subscribe';

//const API_KEY = 'sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK';
export const useStripeCustomer = (uid, email) => {
  const getCustomer = async () => {
    const existingCustomer = await _existingCustomer();
    if (existingCustomer?.length !== 0) {
      return existingCustomer[0];
    }
    const newCustomer = await _createCustomer();
    console.log('NewCustomer>>>>', newCustomer);
    return newCustomer;
  };
  const _existingCustomer = async () => {
    const queryParameters = { query: `metadata['uid']:"${uid}"` };
    try {
      const response = await axios.get(`${BASE_URL}/v1/customers/search`, {
        params: queryParameters,
        headers: {
          Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      console.log('ExistingCustomerData>>>>', _checkResult(response));
      return _checkResult(response).data;
    } catch (error) {
      console.error('Error fetching existing customer:', error);
      return [];
    }
  };
  const _createCustomer = async () => {
    const requestBody = new URLSearchParams({
      email: email,
      'metadata[uid]': uid,
    });
    try {
      const response = await axios.post(`${BASE_URL}/v1/customers`, requestBody, {
        headers: {
          Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      console.log('NewCustomerData>>>>', _checkResult(response));
      return _checkResult(response);
    } catch (error) {
      console.error('Error creating new customer:', error);
      return null;
    }
  };
  const _checkResult = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  };
  return { getCustomer };
};




export const getCards = async (myCustomerId) => {
  const queryParameters = { query: `customerId:"${myCustomerId}"` };
  console.log(myCustomerId);

  try {
    const response = await axios.get(`${FIREBASE_BASE_URL}/customer-cards`, {
      params: {
        customerId: myCustomerId
      },
      headers: {
        Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Check if the response status is 200
    if (response.status === 200) {
      console.log('ExistingCustomerData>>>>', response.data);
      return response.data;
    } else {
      console.error('Unexpected status code:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetching existing customer:', error.message);
    return [];
  }
};

// export const saveCard = async (myCustomerId, paymentMethodId) => {
//   const queryParameters = { query: `customerId:"${myCustomerId, paymentMethodId}"` };
//   console.log(myCustomerId);
//   console.log(paymentMethodId);
// console.log(FIREBASE_BASE_URL);


//   try {
//     const response = await axios.post(`${FIREBASE_BASE_URL}/set-default-payment-method`, {


//       params: {
//         customerId: myCustomerId,
//         paymentMethodId: paymentMethodId,
//       },

//       headers: {
//         Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     });
//     console.log(response);

//     // Check if the response status is 200
//     if (response.status === 200) {
//       console.log('ExistingCustomerData>>>>', response.data);
//       return response.data;
//     } else {
//       console.error('Unexpected status code:', response.status);
//       return [];
//     }
//   } catch (error) {
//     console.error('Error fetching existing customer:', error.message);
//     return [];
//   }
// };


// export const deleteCard = async (myCustomerId, paymentMethodId) => {
//   const queryParameters = { query: `customerId:"${myCustomerId, paymentMethodId}"` };
//   console.log(myCustomerId);
//   console.log(paymentMethodId);
// console.log(FIREBASE_BASE_URL);


//   try {
//     const response = await axios.delete(`${FIREBASE_BASE_URL}/delete-payment-method`, {


//       params: {
//         customerId: myCustomerId,
//         paymentMethodId: paymentMethodId,
//       },

//       headers: {
//         Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     });
//     console.log(response);

//     // Check if the response status is 200
//     if (response.status === 200) {
//       console.log('ExistingCustomerData>>>>', response.data);
//       return response.data;
//     } else {
//       console.error('Unexpected status code:', response.status);
//       return [];
//     }
//   } catch (error) {
//     console.error('Error fetching existing customer:', error.message);
//     return [];
//   }
// };



export const saveCard = async (myCustomerId, paymentMethodId) => {
  console.log(">>>>>>>>>>>>>>>>",myCustomerId)
  console.log(">>>>>>>>>>>>>>>>",paymentMethodId);
  
  
  try {
    const url = `${FIREBASE_BASE_URL}/set-default-payment-method`;
    const params = {
      customerId: myCustomerId,
        paymentMethodId: paymentMethodId,
    };
    const headers = {
      Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    const response = await axios.post(url, params, { headers });
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};




export const deleteCard = async (myCustomerId, paymentMethodId) => {
  try {
    const url = `${FIREBASE_BASE_URL}/delete-payment-method`;
    const params = {
      customerId: myCustomerId,
        paymentMethodId: paymentMethodId,
    };
    const headers = {
      Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    const response = await axios.post(url, params, { headers });
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};



export const addPaymentList = async (myCustomerId, paymentMethodId) => {
  try {
    console.log("?????????????",myCustomerId);
    console.log("?????????????",paymentMethodId);
    
    const url = `${FIREBASE_BASE_URL}/add-payment-methods-list`;
    const params = {
      customer_id: myCustomerId,
      paymentMethodId: paymentMethodId,
    };
    const headers = {
      Authorization: `Bearer sk_test_51Poq3nRrhvPQKbL02yprrYqfKoqkQxyK2AAbOeo9abnY7cNF3qGDcwEy8E60rqGOjFUKBD3uwoEahm5CLZxocycH00FnTCZWhK`,
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    
    const response = await axios.post(url, params,  headers );
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};






